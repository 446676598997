<page-loading *ngIf="!initialized"></page-loading>

<div *ngIf="initialized" id="page-content">
  <button mat-flat-button color="warn" (click)="openLetterDialog()">
    <icon icon="letter" class="icon-in-text-button" style="vertical-align: middle"></icon>
    {{'ActionGenerateContingentLetters' | translate}}
  </button>
  <button mat-flat-button color="primary" (click)="openDownloadDialog()" class="ml">
    <icon icon="download" class="icon-in-text-button" style="vertical-align: middle"></icon>
    {{'Contingents.lettersDownload' | translate}}
  </button>
  <br><br>
  <table mat-table [dataSource]="contingentData" class="table-striped">

    <ng-container matColumnDef="year">
      <th mat-header-cell *matHeaderCellDef>{{prefix+'year' | translate}}</th>
      <td mat-cell *matCellDef="let rate">
        <ng-container *ngIf="rate.year != undefined">{{rate.year}}</ng-container>
        <ng-container *ngIf="rate.year == undefined">
          <h4>{{prefix+'add' | translate}}</h4>
          <mat-form-field appearance="outline" style="margin-top:22px" class="rowspan">
            <mat-label>{{prefix+'year' | translate}}</mat-label>
            <input [(ngModel)]="rateToAdd.year" matInput type="number" name="year" id="year" min="1000" max="9999">
          </mat-form-field>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="month">
      <th mat-header-cell *matHeaderCellDef>{{prefix+'month' | translate}}</th>
      <td mat-cell *matCellDef="let rate">
        <ng-container *ngIf="rate.month != undefined">{{ 'months.'+(rate.month-1) | translate }}</ng-container>
        <ng-container *ngIf="rate.month == undefined">
          <h4 style="opacity: 0;">.</h4>
          <mat-form-field appearance="outline" style="margin-top:22px" class="rowspan">
            <mat-label>{{prefix+'month' | translate}}</mat-label>
            <mat-select id="month" [(ngModel)]="rateToAdd.month">
              <mat-option *ngFor="let m of [0,1,2,3,4,5,6,7,8,9,10,11]" [value]="m+1" [id]="'month_'+m">{{ 'months.'+m | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="rate">
      <th mat-header-cell *matHeaderCellDef>{{prefix+'rate' | translate}} {{prefix+'rateEx' | translate}}</th>
      <td mat-cell *matCellDef="let rate">
        <ng-container *ngIf="rate.rate != undefined">{{rate.rate}}</ng-container>
        <ng-container *ngIf="rate.rate == undefined">
          <h4 style="opacity: 0;">.</h4>
          <mat-form-field appearance="outline" style="margin-top:22px" class="rowspan">
            <mat-label>{{prefix+'rate' | translate}}</mat-label>
            <input [(ngModel)]="rateToAdd.rate" matInput type="number" name="rate" id="rate" min="0" max="1" step="0.01">
          </mat-form-field>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="consumptionGrowthThreshold">
      <th mat-header-cell *matHeaderCellDef>{{prefix+'consumptionGrowthThreshold' | translate}}</th>
      <td mat-cell *matCellDef="let rate">
        <ng-container *ngIf="rate.rate != undefined">{{rate.consumptionGrowthThreshold}}</ng-container>
        <ng-container *ngIf="rate.rate == undefined">
          <h4 style="opacity: 0;">.</h4>
          <mat-form-field appearance="outline" style="margin-top:22px" class="rowspan">
            <mat-label>{{prefix+'consumptionGrowthThreshold' | translate}}</mat-label>
            <input [(ngModel)]="rateToAdd.consumptionGrowthThreshold" matInput type="number" name="threshold" id="threshold" min="0" step="0.01">
          </mat-form-field>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let rate">
        <ng-container *ngIf="rate.year == undefined">
          <h4 style="opacity: 0;">.</h4>
          <button mat-icon-button (click)="addRate(rateToAdd)" [disabled]="!validRate()">
            <icon [icon]="'add-circle'"></icon>
          </button>
        </ng-container>
        <button *ngIf="rate.year != undefined" mat-icon-button (click)="deleteRate(rate)">
          <icon [icon]="'delete'"></icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
