import {Component, Input, OnInit} from '@angular/core';
import {MeteringPointConsumptionData} from "../../model/metering-point-consumption-data";
import {CompanypartnersBaseComponent} from "../../../companypartners/companypartners-base.component";

@Component({
  selector: 'metering-points-consumption-table',
  templateUrl: './metering-points-consumption-table.component.html',
  styleUrls: []
})
export class MeteringPointsConsumptionTableComponent extends CompanypartnersBaseComponent implements OnInit {

  @Input() meteringPointConsumptionData: MeteringPointConsumptionData[] = [];
  displayedColumns = ['year', 'month', 'value','dataSource', 'contingent', 'contingentId', 'growthThresholdRuleState'];
  prefix = 'companyClient.meteringPointInfo.';

  ngOnInit(): void {
    this.meteringPointConsumptionData.forEach(mpd => {
      mpd.month--;
      mpd.value = Math.floor(mpd.value);
      mpd.contingent = Math.floor(mpd.contingent);
    });
    this.meteringPointConsumptionData.sort((a: MeteringPointConsumptionData, b: MeteringPointConsumptionData) => this.dateTimeService.sortByDateNewestFirst(a,b));
  }


}
