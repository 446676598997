<table mat-table [dataSource]="meteringPoints" class="table-striped">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>{{mpPrefix+'id' | translate}}</th>
    <td mat-cell *matCellDef="let meteringPoint"> {{meteringPoint.id}} </td>
  </ng-container>

  <ng-container matColumnDef="mpa">
    <th mat-header-cell *matHeaderCellDef>{{mpPrefix+'vnb' | translate}}</th>
    <td mat-cell *matCellDef="let meteringPoint"> {{meteringPoint.mpa.name}} </td>
  </ng-container>

  <ng-container matColumnDef="address">
    <th mat-header-cell *matHeaderCellDef>{{mpPrefix+'address' | translate}}</th>
    <td mat-cell *matCellDef="let mp"> {{mp.address?.street + " " + mp.address?.houseNumber + ", " + mp.address?.zipCode + " " + mp.address?.town}} </td>
  </ng-container>

  <ng-container matColumnDef="businessPartnerName">
    <th mat-header-cell *matHeaderCellDef>{{mpPrefix+'company' | translate}}</th>
    <td mat-cell *matCellDef="let meteringPoint"> {{meteringPoint.businessPartnerName}} </td>
  </ng-container>

  <ng-container matColumnDef="validFrom">
    <th mat-header-cell *matHeaderCellDef>{{mpPrefix+'validFrom' | translate}}</th>
    <td mat-cell *matCellDef="let meteringPoint"> {{meteringPoint.valid.from ? (meteringPoint.valid.from | monthYear) : ''}} </td>
  </ng-container>

  <ng-container matColumnDef="validTo">
    <th mat-header-cell *matHeaderCellDef>{{mpPrefix+'validTo' | translate}}</th>
    <td mat-cell *matCellDef="let meteringPoint"> {{meteringPoint.valid.to ? (meteringPoint.valid.to | monthYear) : ''}} </td>
  </ng-container>

  <ng-container matColumnDef="excluded">
    <th mat-header-cell *matHeaderCellDef>{{mpPrefix+'excluded' | translate}}</th>
    <td mat-cell *matCellDef="let meteringPoint">
      <span *ngIf="!isExclusionEditable">
        {{(meteringPoint.excluded ? mpPrefix+'isExcluded' : mpPrefix+'isIncluded') | translate}}
      </span>
      <div *ngIf="isExclusionEditable">
        <mat-checkbox class="ml" [(ngModel)]="meteringPoint.excluded" (change)="toggleExclusion(meteringPoint)"/>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let meteringPoint">
      <a *ngIf="!useActionEmitter" mat-icon-button [routerLink]="'/company/contingents/meteringpoints/'+meteringPoint.mpa.id+'/'+meteringPoint.id+'/'+meteringPoint.businessPartnerId" [id]="'info_'+meteringPoint.id">
        <mat-icon svgIcon="chevron-right"></mat-icon>
      </a>
      <button *ngIf="useActionEmitter" mat-icon-button (click)="goToDetails.emit(meteringPoint.id)">
        <mat-icon svgIcon="chevron-right"></mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
