<page-loading-spinner [initialized]="initialized"></page-loading-spinner>
<div class="root fixed-header">
  <div class="headline-space-button">
    <h1>{{'companyClient.navigation.trade' | translate}}</h1>
    <div>
      <a mat-flat-button color="primary" id="newTradeBtn" [routerLink]="'/company/contingents/trade/new'">
        {{prefix+'newTrade' | translate}}
      </a>
    </div>
  </div>
  <contingent-trades-table [tradedContingents]="tradedContingents"/>
</div>
