import {Component, EventEmitter, Input, Output} from '@angular/core';
import {companyservice} from "../../messaging-grpc/messaging-grpc";
import ContingentTrade = companyservice.ContingentTrade;

@Component({
  selector: 'contingent-trades-table',
  templateUrl: './contingent-trades-table.component.html',
  styleUrls: ['./contingent-trades-table.component.scss']
})
export class ContingentTradesTableComponent {
  @Input() tradedContingents: ContingentTrade[] = [];
  @Input() useActionEmitter = false;
  @Output() goToDetails = new EventEmitter<string>();
  prefix = 'companyClient.trade.';
  tcPrefix = 'companyClient.objectProperties.trade.';

  displayedColumns = ['tradeAction', 'period', 'quantity', 'datetime', 'partner', 'action'];
}
