import { Pipe, PipeTransform } from '@angular/core';
import {formatDate} from "@angular/common";

@Pipe({
  name: 'monthYear'
})
export class MonthYearPipe implements PipeTransform {
  transform(value: {year: number, month: number, day: number}, ..._args: unknown[]): unknown {
    return formatDate(new Date(value.year, value.month-1, value.day), 'MM.yyyy', 'en-US');
  }

}
