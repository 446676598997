import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MeteringPoint} from "../../../companypartners/metering-points/metering-point";
import {MatIconComponent} from "../mat-icon-component/mat-icon.component";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'metering-points-table',
  templateUrl: './metering-points-table.component.html',
  styleUrls: []
})
export class MeteringPointsTableComponent extends MatIconComponent implements OnInit {
  @Input() meteringPoints: MeteringPoint[] = [];
  @Input() showAction = false;
  @Input() useActionEmitter = false;
  @Input() isExclusionEditable = false;
  @Output() goToDetails = new EventEmitter<string>();
  @Output() exclusionUpdated = new EventEmitter<MeteringPoint>();

  constructor(protected matIconRegistry: MatIconRegistry,
              protected domSanitizer: DomSanitizer) {
    super(matIconRegistry, domSanitizer);
  }

  prefix = 'companyClient.meteringPoints.';
  mpPrefix = 'companyClient.objectProperties.meteringPoint.';

  displayedColumns = ['id', 'mpa', 'address', 'businessPartnerName', 'validFrom', 'validTo', 'excluded'];

  ngOnInit() {
    if(this.showAction) {
      this.displayedColumns.push('action');
    }
  }

  toggleExclusion(meteringPoint: MeteringPoint) {
    this.exclusionUpdated.emit(meteringPoint);
  }
}
