<h1 mat-dialog-title>{{prefix+'lettersH1' | translate}}</h1>
<form [formGroup]="contingentForm" (ngSubmit)="onSubmit()" method="post">
  <div mat-dialog-content>

    <p>{{prefix+'lettersDescription' | translate}}</p>

    <div class="multi-col-form m-top">
      <div class="col-50">
        <mat-form-field appearance="outline" class="rowspan">
          <mat-label>{{prefix+'year' | translate}}</mat-label>
          <input formControlName="year" matInput type="number" name="Year" id="Year" min="1000" max="9999">
        </mat-form-field>
      </div>
      <div class="col-50">

        <mat-form-field appearance="outline" class="rowspan">
          <mat-label>{{prefix+'month' | translate}}</mat-label>
          <mat-select id="Month" formControlName="month">
            <mat-option *ngFor="let m of [0,1,2,3,4,5,6,7,8,9,10,11]" [value]="m+1" [id]="'month_'+m">{{ 'months.'+m | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-0" style="padding-right:30px">
        <mat-form-field appearance="outline" class="rowspan">
          <mat-label>{{prefix+'dictionaryKey' | translate}}</mat-label>
          <input matInput [formControl]="downloadKey" readonly>
          <button type="button" mat-icon-button matSuffix [cdkCopyToClipboard]="downloadKey.value" [disabled]="downloadKey.value == ''"><icon icon="copy"/></button>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div mat-dialog-actions [align]="'end'" class="padded-actions">
    <button type="button" *ngIf="!isSendingRequest" mat-flat-button (click)="onNoClick()" id="modalCancel">{{'ModalCloseButton' | translate}}</button>
    <submit-button [disabled]="contingentForm.invalid" [isSendingRequest]="isSendingRequest" [textKey]="prefix+'lettersOk'"></submit-button>
  </div>
</form>
