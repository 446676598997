import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {APP_CONFIG, AppConfig} from './config/app.config';

import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/modules/shared.module';
import {CoreModule} from './core/core.module';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SharedServicesModule} from './shared/modules/shared-services.module';
import {environment} from '../environments/environment';
import {ServiceWorkerModule} from '@angular/service-worker';

import {CompanypartnersModule} from "./companypartners/companypartners.module";
import {UsersModule} from "./users/users.module";
import {MAT_DATE_LOCALE} from "@angular/material/core";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                // this loader only support the json format
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        CoreModule,
        SharedModule,
        SharedServicesModule.forRoot(),
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
        CompanypartnersModule,
        UsersModule], providers: [
        { provide: APP_CONFIG, useValue: AppConfig },
        { provide: MAT_DATE_LOCALE, useValue: 'de-CH' },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {

}
