<h1 mat-dialog-title>{{'companyClient.navigation.trade' | translate}}</h1>

<div mat-dialog-content>
  <div class="details-list">
    <div class="row">
      <div class="col-sm-6">
        <div class="details-item">
          <label class="details-label">{{ctPrefix+'action' | translate}}</label>
          <div class="details-value">{{ 'TradingAction.'+data.trade.tradingAction | translate }}</div>
        </div>
        <div class="details-item">
          <label class="details-label">{{ctPrefix+'period' | translate}}</label>
          <div class="details-value">{{ 'months.'+data.trade.month | translate }} {{data.trade.year}}</div>
        </div>
        <div class="details-item">
          <label class="details-label">{{ctPrefix+'tradedQuantity' | translate}}</label>
          <div class="details-value">{{data.trade.tradingQuantity}}</div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="details-item">
          <label class="details-label">{{ctPrefix+'tradeDate' | translate}}</label>
          <div class="details-value">{{data.trade.tradingTimestamp | date:'dd.MM.YYYY HH:mm'}}</div>
        </div>
        <div class="details-item">
          <label class="details-label">{{ctPrefix+'platform' | translate}}</label>
          <div class="details-value">{{data.trade.tradingPlatformOrIntermediary}}</div>
        </div>
        <div class="details-item">
          <label class="details-label">{{ctPrefix+'contingentId' | translate}}</label>
          <div class="details-value">{{data.trade.tradingContingentId}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="details-list">
    <div class="row">
      <div class="col-sm-6">
        <h2>{{prefix+'partnerInfo' | translate}}</h2>
        <div class="details-item">
          <label class="details-label">{{ctPrefix+'company.name' | translate}}</label>
          <div class="details-value">{{data.trade.tpCompanyName}}</div>
        </div>
        <div class="details-item">
          <label class="details-label">{{ctPrefix+'company.uid' | translate}}</label>
          <div class="details-value">{{data.trade.tpUidNumber}}</div>
        </div>
        <div class="details-item">
          <label class="details-label">{{ctPrefix+'contingentId' | translate}}</label>
          <div class="details-value">{{data.trade.tpContingentId}}</div>
        </div>
        <div class="details-item">
          <label class="details-label">{{ctPrefix+'company.responsible' | translate}}</label>
          <div class="details-value">{{data.trade.tpResponsiblePerson}}</div>
        </div>
        <div class="details-item">
          <label class="details-label">{{'Ostral.CompanyDetail.Contact' | translate}}</label>
          <div class="details-value">{{data.trade.tpContactFirstName}} {{data.trade.tpContactName}}</div>
        </div>

        <div class="details-item">
          <label class="details-label">{{'Ostral.CompanyDetail.Address' | translate}}</label>
          <div class="details-value">{{data.trade.tpAddressStreet}} {{data.trade.tpAddressHouseNumber}}</div>
          <div *ngIf="data.trade.tpAddressSuffix" class="details-value">{{data.trade.tpAddressSuffix}}</div>
          <div class="details-value">{{data.trade.tpAddressZipCode}} {{data.trade.tpAddressTown}}</div>
        </div>

        <div class="details-item">
          <label class="details-label">{{ctPrefix+'company.email' | translate}}</label>
          <div class="details-value">{{data.trade.tpEmail}}</div>
        </div>
      </div>

      <div class="col-sm-6">
        <h2>{{prefix+'ownInfo' | translate}}</h2>
        <div class="details-item">
          <label class="details-label">{{ctPrefix+'company.name' | translate}}</label>
          <div class="details-value">{{data.trade.cpCompanyName}}</div>
        </div>
        <div class="details-item">
          <label class="details-label">{{ctPrefix+'company.uid' | translate}}</label>
          <div class="details-value">{{data.trade.cpUidNumber}}</div>
        </div>
        <div class="details-item">
          <label class="details-label">{{ctPrefix+'contingentId' | translate}}</label>
          <div class="details-value">{{data.trade.cpContingentId}}</div>
        </div>
        <div class="details-item">
          <label class="details-label">{{ctPrefix+'company.responsible' | translate}}</label>
          <div class="details-value">{{data.trade.cpResponsiblePerson}}</div>
        </div>
        <div class="details-item">
          <label class="details-label">{{'Ostral.CompanyDetail.Contact' | translate}}</label>
          <div class="details-value">{{data.trade.cpContactFirstName}} {{data.trade.cpContactName}}</div>
        </div>

        <div class="details-item">
          <label class="details-label">{{'Ostral.CompanyDetail.Address' | translate}}</label>
          <div class="details-value">{{data.trade.cpAddressStreet}} {{data.trade.cpAddressHouseNumber}}</div>
          <div *ngIf="data.trade.cpAddressSuffix" class="details-value">{{data.trade.cpAddressSuffix}}</div>
          <div class="details-value">{{data.trade.cpAddressZipCode}} {{data.trade.cpAddressTown}}</div>
        </div>

        <div class="details-item">
          <label class="details-label">{{ctPrefix+'company.email' | translate}}</label>
          <div class="details-value">{{data.trade.cpEmail}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions [align]="'end'" class="padded-actions">
  <button mat-flat-button (click)="onNoClick()" id="modalCancel">{{'ModalCloseButton' | translate}}</button>
</div>
