<table mat-table [dataSource]="meteringPointConsumptionData" class="table-striped">
  <ng-container matColumnDef="year">
    <th mat-header-cell *matHeaderCellDef>{{prefix+'year' | translate}}</th>
    <td mat-cell *matCellDef="let meteringPoint"> {{meteringPoint.year}} </td>
  </ng-container>

  <ng-container matColumnDef="month">
    <th mat-header-cell *matHeaderCellDef>{{prefix+'month' | translate}}</th>
    <td mat-cell *matCellDef="let meteringPoint"> {{'months.'+meteringPoint.month | translate}} </td>
  </ng-container>

  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef>{{prefix+'consumption' | translate}}</th>
    <td mat-cell *matCellDef="let meteringPoint"> {{meteringPoint.value && meteringPoint.value > -1 ? meteringPoint.value.toLocaleString('DE-ch') : ''}} </td>
  </ng-container>

  <ng-container matColumnDef="dataSource">
    <th mat-header-cell *matHeaderCellDef>{{prefix+'dataSource' | translate}}</th>
    <td mat-cell *matCellDef="let meteringPoint"> {{
        (meteringPoint.value && meteringPoint.value > -1) || meteringPoint.dataSource !== 'DATA_SOURCE_UNSPECIFIED' ?
          ('LabelMeteringDataSource.'+meteringPoint.dataSource | translate) :
          '-'}} </td>
  </ng-container>

  <ng-container matColumnDef="contingent">
    <th mat-header-cell *matHeaderCellDef>{{prefix+'contingent' | translate}}</th>
    <td mat-cell *matCellDef="let meteringPoint"> {{meteringPoint.contingent == -1 ? '' : meteringPoint.contingent.toLocaleString('DE-ch')}} </td>
  </ng-container>

  <ng-container matColumnDef="contingentId">
    <th mat-header-cell *matHeaderCellDef>{{prefix+'contingentId' | translate}}</th>
    <td mat-cell *matCellDef="let meteringPoint"> {{meteringPoint.contingentId}} </td>
  </ng-container>

  <ng-container matColumnDef="growthThresholdRuleState">
    <th mat-header-cell *matHeaderCellDef>{{prefix+'growthThresholdRuleState' | translate}}</th>
    <td mat-cell *matCellDef="let meteringPoint"> {{'LabelGrowthThresholdRuleState.'+meteringPoint.growthThresholdRuleState | translate}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
