/* eslint-disable max-len */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UsersRoutingModule} from './users-routing.module';
import {UsersDashboardComponent} from './users-dashboard/users-dashboard.component';

import {UsersComponent} from './users.component';

import {UtilsService} from '../shared/services/utils.service';
import {EnumUtilsService} from '../shared/services/enum-utils/enum-utils.service';

import {AdminUsersComponent} from './admin-users/admin-users.component';
import {MarketpartnerCommunicationWebclientComponent} from './users-marketpartner-communication-webclient/users-marketpartner-communication-webclient.component';
import {MarketpartnerCommunicationMpdComponent} from './users-marketpartner-communication-mpd/users-marketpartner-communication-mpd.component';
import {MarketpartnerCommunicationFtpComponent} from './users-marketpartner-communication-ftp/users-marketpartner-communication-ftp.component';
import {MarketpartnerCommunicationDataComponent} from './users-marketpartner-communication-data/users-marketpartner-communication-data.component';
import {AdminUsersMarketPartnersComponent} from './admin-marketpartners/admin-marketpartners.component';
import {ContactMarketpartnersComponent } from './users-contact-marketpartners/users-contact-marketpartners.component';
import {OperatorSidebarPanelComponent} from './operator-sidebar-panel/operator-sidebar-panel.component';
import {ChangeProcessesComponent} from './users-change-processes/users-change-processes.component';
import {ChangeProcessesInfoComponent} from './users-change-processes-info/users-change-processes-info.component';
import {ReportsComponent} from './users-reports/users-reports.component';
import {NotificationsComponent} from './users-notifications/users-notifications.component';
import {NotificationsInfoComponent} from './users-notifications-info/users-notifications-info.component';
import {MeteringPointsComponent} from './users-metering-points/users-metering-points.component';
import {MeteringPointsInfoComponent} from './users-metering-points-info/users-metering-points-info.component';
import {EchAddressInfoComponent} from './users-metering-points-info/ech-address-info.component';
import {MeteringPointsImportService} from './users-metering-points/metering-points-import.service';
import {MeteringPointsExportService} from './users-metering-points/metering-points-export.service';
import {SdatRequestDialogsService} from './shared/sdat-request-dialogs.service';
import { SdatMessageComponent } from './users-change-processes-info/sdat-message.component';
import { SdatResponseDialogsService } from './shared/sdat-response-dialogs.service';
import { AdminAnnouncementsComponent } from './admin-announcements/admin-announcements.component';
import { NotificationsViewComponent } from './shared/notifications-view.component';
import { MarketpartnerViewComponent } from './shared/marketpartner-view.component';
import { MarketpartnersLoaderService } from './shared/marketpartners-loader.service';
import { OstralComponent } from './users-ostral/users-ostral.component';
import { OstralInfoComponent } from './users-ostral-info/users-ostral-info.component';
import {MarketpartnerModule} from "../shared/marketpartner-components/marketpartner.module";
import {NavComponent} from "./nav/nav.component";
import {SharedModule} from "../shared/modules/shared.module";
import {MaterialModule} from "../shared/material-components/material.module";
import {AdminCompanyPartnersComponent} from "./admin-company-partners/admin-company-partners.component";
import {
  UsersOstralMeteringpointDialogComponent
} from "./users-ostral-meteringpoint-dialog/users-ostral-meteringpoint-dialog.component";
import { AdminCompanyPartnersMeteringpointsComponent } from './admin-company-partners-meteringpoints/admin-company-partners-meteringpoints.component';
import { AdminOstralComponent } from './admin-ostral/admin-ostral.component';
import { IconComponent } from './shared/icon/icon.component';
import { AdminOstralLettersDecreeDialogComponent } from './admin-ostral/decree-download-dialogs/decree-dialog/admin-ostral-letters-decree-dialog.component';
import {
  DeleteMeteringdataDialogComponent
} from "./admin-marketpartners/delete-meteringdata-dialog/delete-meteringdata-dialog.component";
import {
  UsersOstralContingentTradeDialogComponent
} from "./users-ostral-contingent-trade-dialog/users-ostral-contingent-trade-dialog.component";
import {
  AdminOstralLettersDownloadDialogComponent
} from "./admin-ostral/decree-download-dialogs/download-dialog/admin-ostral-letters-download-dialog.component";
import {
  AdminOstralLettersDialogComponent
} from "./admin-ostral/decree-download-dialogs/all-in-one-dialog/admin-ostral-letters-dialog.component";


@NgModule({
  imports: [
    CommonModule,
    MarketpartnerModule,
    UsersRoutingModule,
    SharedModule,
    MaterialModule
  ],
  declarations: [
    UsersComponent,
    UsersDashboardComponent,
    AdminUsersComponent,
    AdminCompanyPartnersComponent,
    AdminUsersMarketPartnersComponent,
    ContactMarketpartnersComponent,
    AdminAnnouncementsComponent,
    NotificationsViewComponent,
    MarketpartnerViewComponent,
    MarketpartnerCommunicationWebclientComponent,
    MarketpartnerCommunicationMpdComponent,
    MarketpartnerCommunicationFtpComponent,
    MarketpartnerCommunicationDataComponent,
    OperatorSidebarPanelComponent,
    ChangeProcessesComponent,
    ChangeProcessesInfoComponent,
    ReportsComponent,
    NotificationsComponent,
    NotificationsInfoComponent,
    MeteringPointsComponent,
    MeteringPointsInfoComponent,
    EchAddressInfoComponent,
    SdatMessageComponent,
    OstralComponent,
    OstralInfoComponent,
    UsersOstralMeteringpointDialogComponent,
    UsersOstralContingentTradeDialogComponent,
    DeleteMeteringdataDialogComponent,
    NavComponent,
    AdminCompanyPartnersMeteringpointsComponent,
    AdminOstralComponent,
    IconComponent,
    AdminOstralLettersDecreeDialogComponent,
    AdminOstralLettersDownloadDialogComponent,
    AdminOstralLettersDialogComponent
  ],
  exports: [
    NavComponent
  ],
  providers: [
    UtilsService,
    EnumUtilsService,
    SdatRequestDialogsService,
    SdatResponseDialogsService,
    MeteringPointsImportService,
    MeteringPointsExportService,
    MarketpartnersLoaderService
  ]
})

export class UsersModule {
}
