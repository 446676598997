/* eslint-disable @angular-eslint/component-selector */
import {Component, ViewChild} from '@angular/core';
import {UtilsService} from '../../shared/services/utils.service';
import {SessionService} from '../../shared/session/session.service';
import {NotificationsService} from '../../shared/services/notifications.service';
import {MarketPartnerService} from '../../shared/services/marketpartner.service';

import {ActivatedRoute, Router} from '@angular/router';
import {SmartModalService} from '../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service';
import {SmartTableBuilder} from '../../shared/marketpartner-components/smart-forms/smart-table/builder/smart-table.builder';
import {SmartTableConfigDefinition} from '../../shared/marketpartner-components/smart-forms/smart-table/classes/SmartTableConfigDefinition';

import {MOCKUP_RESPONSES_LIST} from '../requests/mockup-responses-list';
import {ApiRequestBuilder} from '../../shared/api-request/builder/api-request.builder';
import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';
import {SmartTableComponent} from '../../shared/marketpartner-components/smart-forms/smart-table/component/smart-table.component';
import {SmartFormBuilder} from '../../shared/marketpartner-components/smart-forms/smart-form/builder/smart-form.builder';
import {SmartModalBuilder} from '../../shared/marketpartner-components/smart-forms/smart-modal/builder/smart-modal.builder';
import {ActionEvent} from '../../shared/marketpartner-components/smart-forms/smart-shared/classes/ActionEvent';
import {UserInfoOperatorServer, UserInfoServer, UserInfoTableRow} from '../../shared/session/UserInfo';
import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {EnumUtilsService} from '../../shared/services/enum-utils/enum-utils.service';
import {EnumItem} from '../../shared/services/enum-utils/classes/enum-definition';
import {MarketpartnerViewComponent} from '../shared/marketpartner-view.component';
import {ColumnFilterOperator} from '../../shared/marketpartner-components/smart-forms/smart-table/classes/TableColumnFilter';

@Component({
  selector: 'page-admin-users-content',
  templateUrl: './admin-users.component.html',
  styleUrls: ['../users-common.scss']
})

export class AdminUsersComponent extends MarketpartnerViewComponent {
  @ViewChild('tableMarketPartners') tableMarketPartners?: SmartTableComponent;
  @ViewChild('tableGlobalUsers') tableGlobalUsers?: SmartTableComponent;

  initialized = false;
  isOperator = false;
  isOperatorAdmin = false;

  tableMarketPartnersConfig?: SmartTableConfigDefinition;
  dataSetMarketPartnersUsers: UserInfoServer[] = [];

  tableGlobalUsersConfig?: SmartTableConfigDefinition;
  dataSetGlobalUsers: UserInfoServer[] = [];

  mockUpResponsesCopy: any = null;

  // FIXME: hack required because backend doesn't provide this info inside the table
  partnerId2Name: any = {};

  constructor(public sessionService: SessionService,
              public notificationsService: NotificationsService,
              public marketPartnerService: MarketPartnerService,
              public activatedRoute: ActivatedRoute,
              private utilsService: UtilsService,
              private enumUtilsService: EnumUtilsService,
              public smartModalService: SmartModalService,
              public apiRequestService: ApiRequestService,
              public router: Router) {
    super(activatedRoute, router, sessionService, marketPartnerService, apiRequestService);

    this.isOperator = this.sessionService.isOperator();
    this.isOperatorAdmin = this.sessionService.isOperatorAdmin();

    this.mockUpResponsesCopy = this.utilsService.cloneJSON(MOCKUP_RESPONSES_LIST);

    this.onListChanged = this.onListChanged.bind(this);
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);
    await this.loadAllData();
    const self = this;

    const resetUserAction = {
      id: 'reset_user', title: 'ActionResetUser',
      icon: 'pli-mail-password', className: 'btn-mint',
      fnVisible: (_data: any) => this.isOperator,
      confirmationDialog: {
        title: 'ActionResetUser',
        body: 'AreYouSureConfirmationBodyResetUser'
      }
    };

    this.tableGlobalUsersConfig = new SmartTableBuilder()
      .flagColumnIdAsRefId()

      .addColumnId()
      .addColumnEmail()
      // .addColumnSalutation()  // hidden to avoid line-break
      .addColumnFirstName()
      .addColumnLastName()
      .addColumnAccountType()
      .addColumnUserStatus()
      .addToolbarActionFromInfo({
        id: 'register_user',
        title: 'ActionCreateNewUserAccount',
        icon: 'pli-add-user',
        className: 'btn-primary',
        needsEntrySelected: false,
        fnVisible: () => this.isOperatorAdmin
      })

      .addRowActionFromInfo(resetUserAction)
      .addRowActionDeleteGlobalUser()
      .addRowActionModifyOperatorRole()

      .addFixedColumnFilter('email', {
        operator: ColumnFilterOperator.Contains,
        value: '@'
      })

      .setTransformRawDataFn(function(userInfoOperatorServer: UserInfoOperatorServer) {
        if ((userInfoOperatorServer.id === self.sessionService.getCurrentUserInfoId()) || !self.isOperatorAdmin) {
          userInfoOperatorServer.actionsDisabled = true;
        }
        return userInfoOperatorServer;
      })

      // .setApiRequestConfigInfoFromInfo(USERS_API_ENDPOINTS_LIST.globalUsers)
      .showFilters(true)
      .showSearchField(false)

      .addToolbarActionFromInfo({
        id: '',
        title: 'ActionDownloadOperatorReport',
        icon: 'pli-download',
        className: 'btn-primary',
        fnClicked: () => this.download()
      })

      .build();

    const tableMarketPartnersBuilder = new SmartTableBuilder()
      .addColumnFromInfo({id: 'generated_id', title: '',
                          visible: false})  // required for action buttons to work
      .setColumnNameForId('generated_id')
      .addColumnMarketPartnerNotVisible()
      .addColumnEmail()
      // .addColumnSalutation()  // hidden to avoid line-break
      .addColumnFirstName()
      .addColumnLastName()
      .addFixedColumnFilter('email', {
        operator: ColumnFilterOperator.Contains,
        value: '@'
      });

    if (this.isOperator && !this.isOperatorMode) {
      tableMarketPartnersBuilder.addColumnFromInfo({
        id: 'client_name', title: 'FormPartner',
        filterable: true, sortable: true
      })
      .addColumnAccountType();
    } else {
      if (this.isOperatorMode && this.partnerId) {
        tableMarketPartnersBuilder.addFixedColumnFilter('client_id', {
          operator: ColumnFilterOperator.Equals,
          value: this.partnerId
        } );
      } else {
        tableMarketPartnersBuilder.addToolbarActionFromInfo({
          id: 'invite_marketpartner_user',
          title: 'ActionCreateNewMarketPartnerUserAccount',
          icon: 'pli-factory',
          className: 'btn-primary',
          needsEntrySelected: false
        });
      }
    }

    this.tableMarketPartnersConfig = tableMarketPartnersBuilder
      .addColumnUserScopes()
      .addColumnUserStatus()
      .addRowActionModifyMarketPartnerUser()
      .addRowActionFromInfo(resetUserAction)
      .addRowActionDeleteMarketPartnerUser()

      .setTransformRawDataFn((row: UserInfoTableRow) => {
        if (row.id === this.sessionService.getCurrentUserInfoId()) {
          row.actionsDisabled = true;
        }
        row.client_name = this.partnerId2Name[row.client_id] || row.client_id;
        row.generated_id = row.id + '::' + row.client_id;  // required for action buttons
        return row;
      })

      .showFilters(true)
      .build();

    this.setInitialized(true);
  }

  async loadAllData() {
    await Promise.all([
      this.loadUsersData(),
      this.loadPartnersList()
    ]);
  }


  async loadPartnersList() {
    if (!this.isOperator) {
      return;
    }

    // Do API Call marketpartners
    const apiMarketPartnerConfig = new ApiRequestBuilder()
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnersShortList)
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .build();
    const resultApiRequest = await this.apiRequestService.callApi(apiMarketPartnerConfig);
    if (resultApiRequest.status === 'success' ) {
      if (!resultApiRequest.data) return;
      if (!resultApiRequest.data.partners) return;
      // Register the list on the enum service
      const enumItemsList: EnumItem[] = [];
      for (const p of resultApiRequest.data.partners) {
        this.partnerId2Name[p.partnerSettings.partnerId] = p.partnerSettings.name;
        enumItemsList.push({
          label: p.partnerSettings.name + ' (' + p.partnerSettings.eic + ')',
          data: p.partnerSettings.eic
        });
      }
      this.enumUtilsService.setEnumValues('marketpartners', enumItemsList);
    }

    // Do API Call companypartners
    const filter = {query: { andConditions: [], pageRequest: {pageIndex: 0, pageSize: 1000}}};
    const apiCompanyPartnerConfig = new ApiRequestBuilder()
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.listCompanyPartners)
      .setBodyInfo(filter)
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .build();
    const resultCompanyRequest = await this.apiRequestService.callApi(apiCompanyPartnerConfig);
    if (resultCompanyRequest.status === 'success' ) {
      if (!resultCompanyRequest.data) return;
      if (!resultCompanyRequest.data.companyPartners) return;
      for (const p of resultCompanyRequest.data.companyPartners) {
        this.partnerId2Name[p.companyPartnerSettings.partnerId] = p.companyPartnerSettings.name;
      }
    }
  }

  async loadUsersData() {
    if (this.isOperator ) {
      const apiUsersRequestConfigBuilder = new ApiRequestBuilder();
      apiUsersRequestConfigBuilder.setEndpointInfo(USERS_API_ENDPOINTS_LIST.globalUsers);
      const apiUserRequestConfig =  apiUsersRequestConfigBuilder
        .setHandleLoading(true)
        .setHandleErrorNotification(true)
        .build();
      const resultUserApiRequest = await this.apiRequestService.callApi(apiUserRequestConfig);
      this.dataSetGlobalUsers = resultUserApiRequest.status === 'success' ?  resultUserApiRequest.data : [];
    }

    const apiMarketPartnerUsersRequestConfigBuilder = new ApiRequestBuilder();
    apiMarketPartnerUsersRequestConfigBuilder.setEndpointInfo(
      USERS_API_ENDPOINTS_LIST[this.isOperator ? 'operatorAllMarketPartnerUsers' : 'marketpartnerUsers']
    );
    const apiMarketPartnerRequestConfig = apiMarketPartnerUsersRequestConfigBuilder
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .build();
    const resultMarketPartnerApiRequest = await this.apiRequestService.callApi(apiMarketPartnerRequestConfig);
    this.dataSetMarketPartnersUsers = resultMarketPartnerApiRequest.status === 'success' ?  resultMarketPartnerApiRequest.data : [];
  }

  onRowClicked(_event: any) {
  }

  async onListChanged() {
    await this.loadUsersData();
    if (this.tableMarketPartners) {
      this.tableMarketPartners.reloadNewData(this.dataSetMarketPartnersUsers);
    }
    if (this.tableGlobalUsers) {
      this.tableGlobalUsers.reloadNewData(this.dataSetGlobalUsers);
    }
  }

  async onActionPerformed(event: ActionEvent) {
    const self = this;
    const entitySelected = event.data.length ? event.data[0] : null;
    console.log('action performed:', event);
    switch (event.actionId) {
      case 'register_user':
        self.registerUser();
        break;

      case 'reset_user':
        self.resetUser(entitySelected);
        break;

      case 'delete_global_user':
        self.deleteGlobalUser(entitySelected);
        break;

      case 'invite_marketpartner_user':
        self.inviteMarketPartnerUser();
        break;

      case 'add_marketpartner_user':
        self.linkToMarketPartnerUser(entitySelected);
        break;

      case 'modify_operator_role':
        self.modifyOperatorRole(entitySelected);
        break;

      case 'modify_user_rights':
        self.modifyUserRights(entitySelected);
        break;

      case 'delete_marketpartner_user':
        self.deleteMarketPartnerUser(entitySelected);
        break;
    }
  }

  /* All possible actions */
  registerUser() {
    const self = this;
    let formBuilder = new SmartFormBuilder()
      .addFormFieldEmail()
      .addFormFieldSalutation()
      .addFormFieldFirstname()
      .addFormFieldLastname()
      .addFormFieldTelephone();
    if (this.isOperatorAdmin) {
      formBuilder = formBuilder.addFormFieldAccountTypeWithoutMarketPartner( );
    }  else {
      formBuilder = formBuilder.addFormFieldAccountTypeMarketPartnerReadOnly( );
    }

    const formConfig = formBuilder.setApiSendDataRequestConfigFromInfo(
        new ApiRequestBuilder().setEndpointInfo(USERS_API_ENDPOINTS_LIST.globalRegisterUser).build()
      )
      .showSubmitButton(true, 'ButtonCreate')
      .showCancelButton(true)
      .build();

    const modalOptions = new SmartModalBuilder()
      .setOnSuccessResponse(async function (_serverResponseData: any) {
        self.onListChanged();
      })
      .setTitle('ActionCreateNewUserAccount')
      .setModalCSSClassSize('md')
      .setFormConfigFromInfo(formConfig)
      .build();

    this.smartModalService.showModal(modalOptions);
  }

  async resetUser(userInfoTableRow: UserInfoTableRow) {
    const apiRequestConfig = new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.forgotResetUser)
      .setBodyInfo({'userId': userInfoTableRow.id})
      .build();
    const resultApiRequest = await this.apiRequestService.callApi(apiRequestConfig);
    if (resultApiRequest.status === 'success') {
      this.notificationsService.showNotification({
        type: 'success',
        message: 'RequestSuccessfullyCompleted'
      });
    }
  }

  async deleteMarketPartnerUser(userInfoTableRow: UserInfoTableRow) {
    const apiRequestConfig = new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .setEndpointInfo(
        this.isOperator ? USERS_API_ENDPOINTS_LIST.globalDeleteMarketPartnerUser : USERS_API_ENDPOINTS_LIST.marketpartnerDeleteUser,
        {'userId': userInfoTableRow.id, 'partnerId': userInfoTableRow.client_id})
      .build();
    const resultApiRequest = await this.apiRequestService.callApi(apiRequestConfig);
    if (resultApiRequest.status === 'success') {
      this.onListChanged();
    }
  }

  async linkToMarketPartnerUser(userInfoTableRow: UserInfoTableRow) {
    // Only for Operators
    const self = this;
    const formConfig = new SmartFormBuilder()
      .addFormFieldEmailReadOnly(userInfoTableRow.email)
      .addFormFieldMarketPartnerSelection()
      .addFormFieldScopes('USER')
      .showSubmitButton(true, 'ButtonCreate')
      .showCancelButton(true)
      .build();

    const modalOptions = new SmartModalBuilder()
      .setOnSubmittedForm(async function (submittedForm: any) {
        // Build the request manually since the partnerId should be in the url
        const apiRequestConfig = new ApiRequestBuilder()
          .setHandleLoading(true)
          .setHandleErrorNotification(true)
          .setEndpointInfo(
            USERS_API_ENDPOINTS_LIST.globalLinkToMarketPartner, {'userId': userInfoTableRow.id, 'partnerId': submittedForm.client_id})
          .setBodyInfo(submittedForm)
          .build();
        const resultApiRequest = await self.apiRequestService.callApi(apiRequestConfig);
        if (resultApiRequest.status === 'success') {
          self.onListChanged();
        }
      })
      .setTitle('ActionCreateNewMarketPartnerUserAccount')
      .setModalCSSClassSize('md')
      .setFormConfigFromInfo(formConfig)
      .build();

    this.smartModalService.showModal(modalOptions);
  }

  async inviteMarketPartnerUser() {
    const self = this;
    const formBuilder = new SmartFormBuilder().addFormFieldEmail();

    const formConfig = formBuilder
      .addFormFieldScopes('USER')
      .setApiSendDataRequestConfigFromInfo(
        new ApiRequestBuilder().setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerInviteUser).build()
      )
      .showSubmitButton(true, 'ButtonCreate')
      .showCancelButton(true)
      .build();

    const modalOptions = new SmartModalBuilder()
      .setOnSuccessResponse(async function (_serverResponseData: any) {
        self.onListChanged();
      })
      .setTitle('ActionCreateNewMarketPartnerUserAccount')
      .setModalCSSClassSize('md')
      .setFormConfigFromInfo(formConfig)
      .build();

    this.smartModalService.showModal(modalOptions);
  }

  modifyUserRights(userInfoTableRow: UserInfoTableRow) {
    const self = this;
    const formConfigBuilder = new SmartFormBuilder()
      .addFormFieldEmailReadOnly( userInfoTableRow.email )
      .addFormFieldFirstnameReadOnly( userInfoTableRow.firstname )
      .addFormFieldLastnameReadOnly( userInfoTableRow.lastname );
    if (this.isOperator) {
      formConfigBuilder.addFormFieldMarketPartnerClientReadOnly( userInfoTableRow.client_name );
      formConfigBuilder.setApiSendDataRequestConfigFromInfo(
        new ApiRequestBuilder().setEndpointInfo( USERS_API_ENDPOINTS_LIST.globalEdiMarketPartnerRoles,
          {userId: userInfoTableRow.id, partnerId: userInfoTableRow.client_id}).build()
      );
    } else {
      formConfigBuilder.setApiSendDataRequestConfigFromInfo(
        new ApiRequestBuilder().setEndpointInfo( USERS_API_ENDPOINTS_LIST.marketpartnerEditUserRoles,
          {userId: userInfoTableRow.id}).build()
      );
    }

    const formConfig = formConfigBuilder
      .addFormFieldScopes(userInfoTableRow.scopes)
      .showSubmitButton(true)
      .showCancelButton(true)
      .build();

    const modalOptions = new SmartModalBuilder()
      .setOnSuccessResponse(async function (_serverResponse: any) {
        self.onListChanged();
      })
      .setTitle('ActionModifyUserRights')
      .setModalCSSClassSize('md')
      .setFormConfigFromInfo(formConfig)
      .build();

    this.smartModalService.showModal(modalOptions);
  }

  modifyOperatorRole(userInfoOperatorServer: UserInfoOperatorServer) {
    const self = this;
    const formConfig =  new SmartFormBuilder()
      .addFormFieldAccountTypeWithoutMarketPartner(userInfoOperatorServer.type)
      .setApiSendDataRequestConfigFromInfo(
        new ApiRequestBuilder().setEndpointInfo( USERS_API_ENDPOINTS_LIST.globalEdiOperatorRoles,
          {userId: userInfoOperatorServer.id}).build()
      )
      .showSubmitButton(true)
      .showCancelButton(true)
      .build();

    const modalOptions = new SmartModalBuilder()
      .setOnSuccessResponse(async function (_serverResponse: any) {
        self.onListChanged();
      })
      .setTitle('ActionModifyOperatorRole')
      .setModalCSSClassSize('md')
      .setFormConfigFromInfo(formConfig)
      .build();

    this.smartModalService.showModal(modalOptions);
  }

  async deleteGlobalUser(userInfoOperatorServer: UserInfoOperatorServer) {
    const apiRequestConfig = new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .setEndpointInfo(
        USERS_API_ENDPOINTS_LIST.globalDeleteUser, {userId: userInfoOperatorServer.id})
      .build();
    const resultApiRequest = await this.apiRequestService.callApi(apiRequestConfig);
    if (resultApiRequest.status === 'success') {
      this.onListChanged();
    }
  }

  async download() {
    await this.apiRequestService.downloadFile(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryContactDownload);
  }
}
