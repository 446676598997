export class ChartStyling {
  public static readonly contingentColor = '#51ABD4';
  public static readonly contingentBackgroundColor = 'transparent';
  public static readonly contingentLightColor = 'rgba(81, 171, 212, 0.2)';

  public static readonly contingentSaleColor = '#000000';
  public static readonly contingentSaleBackgroundColor = 'transparent';

  public static readonly contingentPurchaseColor = '#51AB51';
  public static readonly contingentPurchaseBackgroundColor = 'transparent';

  public static readonly currentColor = '#F9BE3C';
  public static readonly currentBackgroundColor = 'rgba(249,190,60,0.2)';

  public static readonly previousColor = '#999999';
  public static readonly previousBackgroundColor = 'rgba(153,153,153,0.2)';

  public static readonly selectedBackgroundColor = 'rgba(81, 171, 212, 0.2)';

  public static readonly warningColor= '#FF0000';
  public static readonly warningBackgroundColor= 'rgba(255, 0, 0, 0.2)';

  public static readonly fontFamily = "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif";
  public static readonly fontSize = 14;
  public static readonly footerWeight = '900';

  public static getOptions(pluginOptions: any, max: number, unit: string, includeStacked: boolean = false) {
    const yScale: {readonly id: string, readonly stacked?: boolean, readonly min: number, readonly suggestedMax: number, readonly position: string, readonly ticks: any, readonly title: any} = {
      id: 'default',
      min: 0,
      suggestedMax: max,
      position: 'left',
      ticks: {
        precision: 0
      },
      title: {
        display: true,
        text: unit,
        align: 'end'
      }
    };
    const stackedScale: {readonly id: string, readonly  stacked: boolean, readonly min: number, readonly suggestedMax: number, readonly position: string, readonly ticks: any, readonly title?: any, readonly visible: boolean} = {
      id: 'stacked',
      stacked: true,
      min: 0,
      visible: false,
      position: 'right',
      suggestedMax: max,
      ticks: {
        display: false,
        precision: 0
      }
    };

    const scales: {readonly y: any, y1?: any} = includeStacked ? {
      y: yScale,
      y1: stackedScale
    } : {
      y: yScale
    };
    return {
      responsive: true,
      locale: 'DE-ch',
      maintainAspectRatio: false,
      font: {
        family: ChartStyling.fontFamily,
        size: ChartStyling.fontSize
      },
      scales: scales,
      layout: {
        padding: {
          right: 10
        }
      },
      elements: {
        point: {
          radius: 0
        }
      },
      plugins: pluginOptions,
      interaction: {
        intersect: false,
        mode: "index"
      }
    } as const;
  }

}
