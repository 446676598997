import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiRequestService} from "../../../../shared/api-request/service/api-request.service";
import {FormBuilder, Validators} from "@angular/forms";
import {USERS_API_ENDPOINTS_LIST} from "../../../requests/api-endpoints-list";
import {ApiRequestConfigInfoDefinition} from "../../../../shared/api-request/classes/ApiRequestConfigInfoDefinition";

@Component({
  selector: 'decree-dialog',
  templateUrl: './admin-ostral-letters-dialog.component.html',
  styleUrls: ['./admin-ostral-letters-dialog.component.scss']
})
export class AdminOstralLettersDialogComponent {
  prefix = 'Contingents.'
  isSendingRequest = false;
  nextMonth = new Date(new Date().getFullYear(), new Date().getMonth()+1, 1);
  contingentForm = this.fb.nonNullable.group({
    year: [this.nextMonth.getFullYear(), {validators: Validators.required}],
    month: [this.nextMonth.getMonth()+1, {validators: Validators.required}] // Dropdown / Backend is 1-based
  });


  constructor(
    public dialogRef: MatDialogRef<AdminOstralLettersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {partnerId: number, businessPartnerId: string},
    private requestService: ApiRequestService,
    protected readonly fb: FormBuilder
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.isSendingRequest = true;

    let apiCallInfo: ApiRequestConfigInfoDefinition = USERS_API_ENDPOINTS_LIST.generateContingentLettersByBusinessPartners;

    const formData: {year: number, month: number} = this.contingentForm.getRawValue();
    const requestData = {
      Year: String(formData.year),
      Month: String(formData.month),
      PartnerId: String(this.data.partnerId),
      BusinessPartnerId: this.data.businessPartnerId
    };

    this.requestService.downloadFile(apiCallInfo, requestData)
      .then(_ => {
        this.isSendingRequest = false;
        this.dialogRef.close(true);
      });
  }

}
