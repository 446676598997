import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {throwIfAlreadyLoaded} from './module-import-guard';

import {RouterModule} from '@angular/router';
import {UtilsService} from '../shared/services/utils.service';
import {CustomTranslateService} from '../shared/services/custom-translate.service';
import {DateTimeService} from '../shared/services/date-time.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule
  ],
  exports: [
  ],
  declarations: [

  ],
  providers: [
    UtilsService,
    CustomTranslateService,
    DateTimeService
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
