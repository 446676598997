<h1 mat-dialog-title>{{prefix+'lettersDownload' | translate}}</h1>
<form [formGroup]="downloadForm" (ngSubmit)="onSubmit()" method="post">
  <div mat-dialog-content>

    <div class="multi-col-form m-top">
      <div class="col-0">
        <mat-form-field appearance="outline" class="rowspan">
          <mat-label>{{prefix+'dictionaryKey' | translate}}</mat-label>
          <input matInput formControlName="BlobDictionaryKeystr">
        </mat-form-field>

        <div class="rowspan">
          {{prefix+'availableDictionaryKeys' | translate}}<br>
          <div style="font-size: 13px; white-space: nowrap" *ngFor="let key of dictionary">
            <button type="button" mat-button (click)="downloadForm.patchValue({BlobDictionaryKeystr: key})">{{key}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions [align]="'end'" class="padded-actions">
    <button type="button" *ngIf="!isSendingRequest" mat-flat-button (click)="onNoClick()" id="modalCancel">{{'ModalCloseButton' | translate}}</button>
    <submit-button [disabled]="downloadForm.invalid" [isSendingRequest]="isSendingRequest" [textKey]="prefix+'lettersDownload'"></submit-button>
  </div>
</form>
