import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {ConsumptionComponent} from "./consumption/consumption.component";
import {UserManagementComponent} from "./user-management/user-management.component";
import {MeteringPointsComponent} from "./metering-points/metering-points.component";
import {ReportsComponent} from "./reports/reports.component";
import {FeatureGuard} from "./feature.guard";
import {AccountComponent} from "./account/account.component";
import {CompanySettingsComponent} from "./company-settings/company-settings.component";
import {MeteringPointInfoComponent} from "./metering-point-info/metering-point-info.component";
import {ContingentTradeComponent} from "./contingent-trade/contingent-trade.component";
import {ContingentTradeInfoComponent} from "./contingent-trade-info/contingent-trade-info.component";

const usersRoutes: Routes = [
  {path: '', redirectTo: 'contingents/consumption', pathMatch: 'full'},
  {path: 'contingents', redirectTo: 'contingents/consumption', pathMatch: 'full'},
  {path: 'contingents/consumption', component: ConsumptionComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'contingents/meteringpoints', component: MeteringPointsComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'contingents/meteringpoints/:adminId/:id/:businessPartnerId', component: MeteringPointInfoComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'contingents/trade', component: ContingentTradeComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'contingents/trade/new', component: ContingentTradeInfoComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'contingents/trade/:id', component: ContingentTradeInfoComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'contingents/reports', component: ReportsComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'usermanagement', component: UserManagementComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'account', component: AccountComponent, pathMatch: 'full', canActivate: [FeatureGuard]},
  {path: 'settings', component: CompanySettingsComponent, pathMatch: 'full', canActivate: [FeatureGuard]}
];

@NgModule({
  imports: [
    RouterModule.forChild(usersRoutes)
  ],

  exports: [
    RouterModule
  ]
})
export class CompanypartnersRoutingModule { }
