import {Component, OnInit} from '@angular/core';
import {CompanypartnersBaseComponent} from "../companypartners-base.component";
import {COMPANYPARTNERS_API_ENDPOINTS_LIST} from "../api-endpoints-list";
import {companyservice} from "../../shared/messaging-grpc/messaging-grpc";
import ContingentTrade = companyservice.ContingentTrade;

@Component({
  selector: 'contingent-trade',
  templateUrl: './contingent-trade.component.html',
  styleUrls: ['./contingent-trade.component.scss']
})
export class ContingentTradeComponent extends CompanypartnersBaseComponent implements OnInit {
  prefix = 'companyClient.trade.';

  tradedContingents: ContingentTrade[] = [];

  ngOnInit() {
    this.requestService.doApiRequest(COMPANYPARTNERS_API_ENDPOINTS_LIST.listContingentTrades, {tradeId: 0, cpPartnerId: this.partnerId, vnbPartnerId: 0, businessPartnerId: ''})
      .then(trades => {
        this.tradedContingents = trades.data.contingentTrade.map((trade: ContingentTrade) => {
          // @ts-ignore
          let date: Date | null = new Date(trade.tradingTimestamp);
          if(date.getTime() === 0) {
            date = null;
          }
          return {...trade, tradingTimestamp: date};
        });
        this.initialized = true;
      });
  }
}
