import { Component, Input, EventEmitter, Output } from '@angular/core';
import { sdatwebclient, base, sdat } from '../../shared/messaging-grpc/messaging-grpc';
import { SmartFormConfigDefinition } from '../../shared/marketpartner-components/smart-forms/smart-form/classes/SmartFormConfigDefinition';
import { SmartFormBuilder } from '../../shared/marketpartner-components/smart-forms/smart-form/builder/smart-form.builder';
import { SmartFormFormFieldDefinition } from '../../shared/marketpartner-components/smart-forms/smart-form/classes/SmartFormFormFieldDefinition';
import { DateTimeService } from '../../shared/services/date-time.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogCallbacks, SdatResponseDialogsService } from '../shared/sdat-response-dialogs.service';
import { ApiRequestService } from '../../shared/api-request/service/api-request.service';
import { USERS_API_ENDPOINTS_LIST } from '../requests/api-endpoints-list';
import { SMART_FORM_OBJECTSARRAY_DEFS } from '../../shared/marketpartner-components/smart-forms/smart-form/definitions/smart-form-formobjectsarray.definitions';
import * as moment from 'moment-timezone';
import { SdatRequestDialogsService } from '../shared/sdat-request-dialogs.service';
import { LastResponses } from './users-change-processes-info.component';

@Component({
  selector: 'app-sdat-message',
  templateUrl: './sdat-message.component.html',
  styleUrls: ['../users-common.scss']
})
export class SdatMessageComponent {

  formConfigHeader?: SmartFormConfigDefinition;
  formConfigBody?: SmartFormConfigDefinition;
  ackOfAcceptance: boolean = false;

  @Output() reloadRequest = new EventEmitter<void>();

  @Input()
  lastResponses: LastResponses | undefined = undefined;

  @Input()
  set message(message: sdatwebclient.DocumentDetails) {
    const builderHeader = new SmartFormBuilder();
    const builderBody = new SmartFormBuilder().setObjectsArrayDefinitions(SMART_FORM_OBJECTSARRAY_DEFS);
    const formDataHeader: any = {};
    const formDataBody: any = {};
    let fieldNo = 1;

    function addFieldHeader(data: any, info: SmartFormFormFieldDefinition) {
      if (data != null) {
        info.name = fieldNo.toString();
        formDataHeader[fieldNo.toString()] = data;
        builderHeader.addReadOnly(info);
        fieldNo++;
      }
    }
    function addFieldBody(data: any, info: SmartFormFormFieldDefinition) {
      if (data != null) {
        info.name = fieldNo.toString();
        formDataBody[fieldNo.toString()] = data;
        builderBody.addReadOnly(info);
        fieldNo++;
      }
    }

    // XXX: custom HTML layout might be easier than SmartForm here...
    const translateRole = (role: any) => {
      if (!role) return '-';
      // return this.translate.instant('SDATBusinessRole.' + role);  -- translations not yet required
      return role;
    };

    if (!message.content || !message.document || !message.content.header) {
      throw new Error('message without .document/.content.header');
    }

    {
      this.ackOfAcceptance = message.document.ackOfAcc?.value ?? false;
      const { businessReason, direction, messageClass } = message.document;
      const {sender, recipient, creation, id, 'type': type_} = message.content.header;

      addFieldHeader(businessReason, {
        name: '', title: 'ColumnChangeProcess',
        type: 'select', allowedValues: {type: 'globalRegister', data: 'sdatbusinessreason'},
        cssClasses: 'col-xs-4',
      });
      addFieldHeader(direction, {
        name: '', title: 'SDATMessageDirection',
        type: 'select', allowedValues: {type: 'globalRegister', data: 'sdatprocessdirection'},
        cssClasses: 'col-xs-4',
      });
      addFieldHeader(messageClass, {
        name: '', title: 'ColumnMessageClass',
        type: 'select', allowedValues: {type: 'globalRegister', data: 'messageclass'},
        cssClasses: 'col-xs-4',
      });

      // FIXME: major type screw-up (int vs string enums)
      // if (direction === base.Direction.INCOMING && ...) {
      type Direction = keyof typeof base.Direction;
      type BusinessReason = keyof typeof sdat.BusinessReason;
      type MessageClass = keyof typeof sdat.MessageClass;

      let dialogCallBacks: DialogCallbacks | undefined;
      if ((direction as any as Direction === 'INCOMING') && (messageClass as any as MessageClass === 'REQUEST')) {
        const br = businessReason as any as BusinessReason;
        dialogCallBacks = this.sdatResponseDialogsService.getButtonCallbacks(br);

      }
      else if ((direction as any as Direction === 'INCOMING') && (messageClass as any as MessageClass === 'PROCESS_CANCELLATION_REQUESTED')) {
        dialogCallBacks = this.sdatResponseDialogsService.getCancelProcessButtonCallbacks();
      }

      if (dialogCallBacks?.acceptDialog) {
        const acceptDialog = dialogCallBacks.acceptDialog;
        builderBody.addButtonFromInfo({id: '',
          title: 'SDATButtons.AcceptDialog',
          // icon: 'pli-stamp',
          cssClasses: 'btn btn-primary',
          fnClicked: async () => {
            const result = await acceptDialog(message, this.lastResponses);
            if (result) this.reloadRequest.emit();
          },
        });
      }
      if (dialogCallBacks?.rejectDialog) {
        const rejectDialog = dialogCallBacks.rejectDialog;
        builderBody.addButtonFromInfo({
          id: '',
          title: 'SDATButtons.RejectDialog',
          // icon: 'pli-arrow-x-right',
          cssClasses: 'btn btn-primary',
          fnClicked: async () => {
            const result = await rejectDialog(message, this.lastResponses);
            if (result) this.reloadRequest.emit();
          },
        });
      }
      builderBody.addButtonFromInfo({
        id: 'notification_download',
        title: 'ActionNotificationDownload',
        icon: 'pli-download',
        cssClasses: 'btn btn-success',
        fnClicked: () => this.download(message)
      });

      if ((direction as any as Direction === 'OUTGOING')
        && (messageClass as any as MessageClass !== 'DOCUMENT_CANCELLED')
        && (messageClass as any as MessageClass !== 'PROCESS_CANCELLATION_REQUESTED')
        && (messageClass as any as MessageClass !== 'PROCESS_CANCELLATION_CONFIRMED')
        && (messageClass as any as MessageClass !== 'PROCESS_CANCELLATION_REJECTED')
        && ((businessReason as any as BusinessReason === 'SUPPLIER_CHANGE')
          || (businessReason as any as BusinessReason === 'SUPPLIER_END')
          || (businessReason as any as BusinessReason === 'CONSUMER_CHANGE')
          || (businessReason as any as BusinessReason === 'CONSUMER_END')
          || (businessReason as any as BusinessReason === 'ASP_START')
          || (businessReason as any as BusinessReason === 'ASP_END')
          || (businessReason as any as BusinessReason === 'BASE_SUPPLY_REGISTRATION_BASE')
          || (businessReason as any as BusinessReason === 'BASE_SUPPLY_REGISTRATION_SUBSTITUTE'))) {
        builderBody.addButtonFromInfo({
          id: 'notification_annulation',
          title: 'ActionAnnul',
          icon: 'pli-cross',
          cssClasses: 'btn btn-success',
          fnClicked: async () => {
            const result = await this.sdatRequestDialogService.annulDocument(message)
            if (result) this.reloadRequest.emit();
          }
        });
      }

      addFieldHeader(sender ? `${sender.party?.eic} (${sender.name}) / ${translateRole(sender.party?.role)}` : '-', {
        name: '', title: 'ColumnNotificationsSenderEIC',
      });
      addFieldHeader(recipient ? `${recipient.party?.eic} (${recipient.name}) / ${translateRole(recipient.party?.role)}` : '-', {
        name: '', title: 'ColumnNotificationsRecipientEIC',
      });

      const creationTs = creation ? this.dateTimeService.getMomentFromJsonDateTime(creation) : null;
      addFieldHeader(creationTs ? creationTs.format('LLL') : '-', {
        name: '', title: 'ColumnNotificationsTimestampCreated',
      });
      addFieldHeader(id ? id : '-', {
        name: '', title: 'ColumnNotificationsMessageId',
      });

      const typeLabel = type_ ? this.translate.instant('SDATMessageTypes.' + type_) : '-';
      addFieldHeader(typeLabel, {
        name: '', title: 'LabelSDATMessageType',
        // type: 'select', allowedValues: {type: 'globalRegister', data: 'sdatmessagetypes'},
      });
    }

    if (message.content.document?.header) {
      const {documentId, processId, requestingDocumentId, reason} = message.content.document.header;
      addFieldBody(documentId, {
        name: '', title: 'LabelSDATDocumentId',
      });
      addFieldBody(requestingDocumentId, {
        name: '', title: 'LabelSDATRequestingDocumentId',
      });
      addFieldBody(processId, {
        name: '', title: 'ColumnProcessId',
      });

      if (reason && reason.value) {
        addFieldHeader(this.translate.instant('SDATRejectionReason.' + reason.value), {
          name: '', title: 'LabelSDATRejectionReason',
        });
      }
    }

    if (message.content.document?.body) {
      const {
        meteringPointId, startDate, endDate,
        balanceResponsible, balanceSupplier,
        ancillaryServiceProvider, ancillaryServiceProviders,
        gridBillingMethod, consumer, interval, area, aggregationCriteria,
        mpCharacteristics, mpAddress, contractTerminationInfo
      } = message.content.document.body;
      {
        const formatDate = (date: any) => {
          const d = this.dateTimeService.getMomentFromJsonDateTime(date);
          return d ? d.format('LL') : null;
        };
        const formatDateTime = (date: any) => {
          const d = this.dateTimeService.getMomentFromJsonDateTime(date);
          return d ? d.format(moment.localeData().longDateFormat('L') + ' HH:mm') : null;
        };

        addFieldBody(formatDate(startDate), {
          name: '', title: 'LabelSDATStartDate',
        });
        addFieldBody(formatDateTime(interval?.startDateTime), {
          name: '', title: 'LabelSDATStartDate',
        });
        addFieldBody(formatDate(endDate), {
          name: '', title: 'LabelSDATEndDate',
        });
        addFieldBody(formatDateTime(interval?.endDateTime), {
          name: '', title: 'LabelSDATEndDate',
        });
        addFieldBody(formatDate(contractTerminationInfo?.date), {
          name: '', title: 'LabelSDATContractTerminationInfoDate',
        });
      }
      addFieldBody(meteringPointId, {
        name: '', title: 'MeteringPoints.ColumnId',
      });
      addFieldBody(area, {
        name: 'areaEic', title: 'SDATDialogs.FormFields.AreaEic'
      });
      addFieldBody(balanceResponsible, {
        name: '', title: 'SDATDialogs.FormFields.BalanceResponsibleEic',
      });
      addFieldBody(balanceSupplier, {
        name: '', title: 'LabelSDATBalanceSupplierEic',
      });
      addFieldBody(ancillaryServiceProvider, {
        name: '', title: 'MeteringPoints.ColumnAncillaryServiceProvider',
      });
      addFieldBody(aggregationCriteria?.meteringPointType, {
        name: '', title: 'SDATDialogs.FormFields.MeteringPointType',
      });
      addFieldBody(aggregationCriteria?.settlementMethod, {
        name: '', title: 'MeteringPointEvent.Keys.settlementMethod',
      });
      addFieldBody(mpCharacteristics, {
        name: '', title: 'SDATDialogs.FormFields.MeteringPointCharacteristicsTitle',
        type: 'object', objectId: 'meteringPointCharacteristicsObject', cssClasses: 'col-xs-12'
      });
      addFieldBody(mpAddress, {
        name: '', title: 'SDATDialogs.FormFields.MeteringPointAddress',
        type: 'object', objectId: 'meteringPointAddressObject', cssClasses: 'col-xs-12'
      });
      if (ancillaryServiceProviders) {
        addFieldBody(ancillaryServiceProviders.value, {
          name: '', title: 'SDATDialogs.FormFields.AncillaryServiceProviderEics',
          type: 'input', cssClasses: 'col-xs-12', commaSeparatedList: true,
        });
      }
      if (gridBillingMethod) {
        addFieldBody(gridBillingMethod.value, {
          name: '', title: 'SDATDialogs.FormFields.GridBillingMethod',
          type: 'select', allowedValues: {type: 'globalRegister', data: 'gridbillingmethod'}
        });
      }
      if (consumer) {
        addFieldBody(consumer, {
          name: 'consumer', title: 'SDATDialogs.FormFields.Contact',
          type: 'object', cssClasses: 'col-xs-12', objectId: 'consumerObject'
        });
      }
    }

    builderHeader
      .setInitData(formDataHeader)
      .showSubmitButton(false)
      .showCancelButton(false);
    this.formConfigHeader = builderHeader.build();

    builderBody
      .setInitData(formDataBody)
      .showSubmitButton(false)
      .showCancelButton(false);

    this.formConfigBody = builderBody.build();
  }

  constructor(
    private dateTimeService: DateTimeService,
    private translate: TranslateService,
    private sdatResponseDialogsService: SdatResponseDialogsService,
    private sdatRequestDialogService: SdatRequestDialogsService,
    public apiRequestService: ApiRequestService,
  ) { }

  private download(message: sdatwebclient.DocumentDetails) {
    if (!message.document || !message.document.messageMetadata) {
      console.error('incomplete messageMetadata!');
      return;
    }
    const messageMetadata = message.document.messageMetadata;
    // note: google.protobuf.IStringValue cannot be used as string (type-generation screw-up)
    const messageUuid = messageMetadata.messageUuid as string;
    this.apiRequestService.downloadFile({
      ...USERS_API_ENDPOINTS_LIST.downloadProcessMessage,
      replaceUrl: {messageUuid}
    }, {
      Filename: messageMetadata.fileName as string
    });
  }
}
