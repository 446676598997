import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {TranslateCut} from '../services/translate-cut.pipe';

import {EnumUtilsService} from '../services/enum-utils/enum-utils.service';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MonthYearPipe} from "../services/date-time.pipe";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule
    // AccordionModule.forRoot()
  ],
  declarations: [
    TranslateCut,
    MonthYearPipe
  ],
  providers: [
    EnumUtilsService,
  ],
  exports: [
    TranslateModule,
    TranslateCut,
    MonthYearPipe,
    FormsModule,
    ReactiveFormsModule
  ]
})

export class SharedModule {
}
