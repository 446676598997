<!--NAVBAR-->
<!--===================================================-->
<header id="navbar">
  <div id="navbar-container" class="boxed">

    <!--Brand logo & name-->
    <!--================================-->
    <div class="navbar-header">
      <a class="navbar-brand">
        <div class="brand-title">
          <img [src]="logoUrl" alt="Swisseldex Datahub" style="width: 100%;">
        </div>
      </a>
    </div>
    <!--================================-->
    <!--End brand logo & name-->


    <!--Navbar Dropdown-->
    <!--================================-->
    <div class="navbar-content">
      <ul class="nav navbar-top-links">

        <!--Navigation toogle button-->
        <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
        <li class="tgl-menu-btn">
          <a class="mainnav-toggle btn-a ">
            <i class="pli-list-view"></i>
          </a>
        </li>
        <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
        <!--End Navigation toogle button-->
        <li *ngIf="updatesAvailable$ | async">
          <div class="alert alert-danger custom-navbar-alert" (click)="reloadPage()">
            <span>
              {{'UpdatesNotificationPleaseReload' | translate}}
            </span>
          </div>
        </li>

        <li *ngIf="hasFtpWarnings$ | async">
          <div class="alert alert-danger custom-navbar-alert" routerLink="/users/communication/ftp">
            <span>
              {{'CommunicationsFTPIncompleteNotificationWithNavigationLink' | translate | translateCut: '0'}}<span style="text-decoration: underline;">{{'CommunicationsFTPIncompleteNotificationWithNavigationLink' | translate | translateCut: '1'}}</span>{{'CommunicationsFTPIncompleteNotificationWithNavigationLink' | translate | translateCut: '2'}}
            </span>
          </div>
        </li>
      </ul>
      <ul class="nav navbar-top-links">

        <!--User dropdown-->
        <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
        <li id="dropdown-user" class="dropdown">
          <a data-toggle="dropdown" class="dropdown-toggle text-right btn-a">
            <span class="ic-user pull-right">
                <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
              <!--You can use an image instead of an icon.-->
              <!--<img class="img-circle img-user media-object" src="img/profile-photos/1.png" alt="Profile Picture">-->
              <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
                <i class="pli-male"></i>
            </span>
            <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
            <!--You can also display a user name in the navbar.-->
            <!--<div class="username hidden-xs">Aaron Chavez</div>-->
            <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
          </a>


          <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right panel-default">
            <ul class="head-list">

              <li>
                <a (click)="changeProfile()" class="btn-a"><!--<span class="label label-success pull-right">New</span>--><i class="pli-profile icon-lg icon-fw"></i> {{'NavProfile' | translate}}</a>
              </li>
              <li>
                <a (click)="changeLanguageMenuOption()" class="btn-a"><span class="badge badge-info pull-right">{{customTranslateService.getCurrentLangShort().toUpperCase()}}</span><i class="pli-globe icon-lg icon-fw"></i> {{'NavChangeLanguage' | translate}}</a>
              </li>
              <li>
                <a (click)="changePasswordMenuOption()" class="btn-a"><i class="pli-key icon-lg icon-fw"></i> {{'NavChangePassword' | translate}}</a>
              </li>
              <li>
                <a (click)="logoutClicked()" class="btn-a"><i class="pli-unlock icon-lg icon-fw"></i> {{'NavLogout' | translate}}</a>
              </li>
            </ul>
          </div>
        </li>
        <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
        <!--End user dropdown-->

      </ul>
    </div>
    <!--================================-->
    <!--End Navbar Dropdown-->

  </div>
</header>
<!--===================================================-->
<!--END NAVBAR-->




<!--MAIN NAVIGATION-->
<!--===================================================-->
<nav id="mainnav-container" >
  <div id="mainnav">



    <!--Menu-->
    <!--================================-->
    <div id="mainnav-menu-wrap">
      <div class="nano">
        <div class="nano-content">

          <!--Profile Widget-->
          <!--================================-->
          <div id="mainnav-profile" class="mainnav-profile">
            <div class="sidebar-logo-container">
              <img [src]="logoUrl" alt="Swisseldex Datahub" class="sidebar-logo blue-logo">
            </div>


            <div class="profile-wrap text-center">
              <p class="mnp-name">{{sessionService.getCurrentUserInfoFirstname()}} {{sessionService.getCurrentUserInfoLastname()}}</p>
              <div class="mnp-desc">{{sessionService.getCurrentUserInfoEmail()}} </div>
            </div>
            <div class="partner-group-wrap text-center">

                <div *ngIf="sessionService.areMultiplePartnersAvailable()" class="btn-group dropdown">
                  <button class="btn btn-default  dropdown-toggle dropdown-toggle-icon" data-toggle="dropdown" type="button">
                    <span class="partner-group-name">{{sessionService.getCurrentPartnerName()}}</span> <i class="dropdown-caret"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li *ngFor="let partnerInfo of sessionService.getPartnerList()"><a (click)="changeLoggedPartner(partnerInfo.partnerId)">{{partnerInfo.partnerName}}</a></li>
                  </ul>
                </div>
              <div *ngIf="!sessionService.areMultiplePartnersAvailable()" class="btn-group dropdown">
                {{ sessionService.isOperator() ?  ('UserTypeOperator' | translate) : sessionService.getCurrentPartnerName()}}
              </div>
            </div>
          </div>



          <ul id="mainnav-menu" class="list-group">

            <!--Category name-->
            <li class="list-header"> {{'NavigationTitle' | translate }}</li>

            <!--Menu list item-->
            <li *ngFor="let mainNavigation of leftSideNavigationOptions"
                class="{{mainNavigation.cssClasses}}"
                [class.active-sub]="isLeftSideNavigationActive(mainNavigation)"
                [class.active]="isLeftSideNavigationActive(mainNavigation)"
                [class.display-none]="!isUserAllowedToSection(mainNavigation)">
              <a *ngIf="(mainNavigation.cannotBeVisited && isUserAllowedToSection(mainNavigation))" (click)="navigationSelected()" class="btn-a" >
                <i *ngIf="mainNavigation.icon" class={{mainNavigation.icon}}></i>
                <span class="menu-title">{{mainNavigation.title | translate}}</span>
                <i class="arrow"></i>
              </a>
              <a *ngIf="(!mainNavigation.cannotBeVisited && isUserAllowedToSection(mainNavigation))" routerLink="{{mainNavigation.fullPath}}" (click)="navigationSelected()" class="btn-a">
                <i *ngIf="mainNavigation.icon" class={{mainNavigation.icon}}></i>
                <span class="menu-title btn-a">{{mainNavigation.title | translate}}</span>
                <span *ngIf="getNotificationCountObservable(mainNavigation) | async as count" class="pull-right badge" [class.badge-warning]="mainNavigation.notificationWarning">
                  {{ count }}
                </span>
              </a>

              <!--Submenu-->
              <ul *ngIf="mainNavigation.subRoutes && isUserAllowedToSection(mainNavigation)"  [class.in]="isLeftSideNavigationActive(mainNavigation)" class="collapse">
                <li *ngFor="let subRouteNavigation of mainNavigation.subRoutes"
                    class="{{subRouteNavigation.cssClasses}}"
                    [class.active-link]="isLeftSideNavigationActive(subRouteNavigation)"
                    [class.display-none]="!isUserAllowedToSection(subRouteNavigation)">
                  <a routerLink="{{subRouteNavigation.fullPath}}" (click)="navigationSelected()" class="btn-a">
                    <i *ngIf="subRouteNavigation.icon" class={{subRouteNavigation.icon}}></i>
                    <span class="menu-title btn-a">{{subRouteNavigation.title | translate}}</span>
                  </a>
                </li>
              </ul>

            </li>
          </ul>


          <!--Widget-->
          <!--================================-->
          <div class="mainnav-widget">
            <!-- Hide the content on collapsed navigation -->
            <div id="demo-wg-server" class="hide-small mainnav-widget-content">
              <ul class="list-group">
                <li class="pad-ver">
                  <div class="text-center">
                    <div style="margin-bottom: 10px;">
                      <i class="pli-mail-read icon-3x"></i>
                    </div>
                    {{'NavSideQuestions' | translate}}
                    <p class="text-lg text-semibold text-main"> <a href="mailto:support@swisseldex.ch">support&#64;swisseldex.ch </a> </p>
                    <a href="tel:+41434561561">043 456 1561</a><br/>
                    <span class="text-muted">{{'NavSideOfficeHours' | translate}}</span>
                    <p class="text-md" style="margin-top: 10px;">
                      <a [href]="this.userManualService.getQuickstartLink('mp')" target="_blank">
                        <i class="pli-open-book icon-1x"></i>
                        {{'NavSideQuickstartGuide'+ (showOstralGuide ? 'Datahub' : '') | translate}}
                      </a>
                    </p>
                    <p *ngIf="showOstralGuide" class="text-md" style="margin-top: 10px;">
                      <a [href]="this.userManualService.getQuickstartLink('mpa')" target="_blank">
                        <i class="pli-open-book icon-1x"></i>
                        {{'NavSideQuickstartGuideOstral' | translate}}
                      </a>
                    </p>
                    <p class="text-lg" style="margin-top: 10px;">
                      <a [href]="this.userManualService.getUserManualLink()" target="_blank">
                        <i class="pli-open-book icon-2x"></i>
                        {{'NavSideUserManual' | translate}}
                      </a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!--================================-->
          <!--End widget-->

        </div>
      </div>
    </div>
    <!--================================-->
    <!--End menu-->

  </div>
</nav>
<!--===================================================-->
<!--END MAIN NAVIGATION-->

<div class="boxed">
  <div id="content-container">
    <router-outlet *ngIf="!hideRoute"></router-outlet>
  </div>
</div>

<smart-modal></smart-modal>
