import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiRequestService} from "../../../../shared/api-request/service/api-request.service";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {USERS_API_ENDPOINTS_LIST} from "../../../requests/api-endpoints-list";
import {ApiRequestConfigInfoDefinition} from "../../../../shared/api-request/classes/ApiRequestConfigInfoDefinition";

@Component({
  selector: 'decree-dialog',
  templateUrl: './admin-ostral-letters-decree-dialog.component.html',
  styleUrls: ['./admin-ostral-letters-decree-dialog.component.scss']
})
export class AdminOstralLettersDecreeDialogComponent {
  prefix = 'Contingents.'
  isSendingRequest = false;
  nextMonth = new Date(new Date().getFullYear(), new Date().getMonth()+1, 1);
  downloadKey = new FormControl('');
  contingentForm = this.fb.nonNullable.group({
    year: [this.nextMonth.getFullYear(), {validators: Validators.required}],
    month: [this.nextMonth.getMonth()+1, {validators: Validators.required}] // Dropdown / Backend is 1-based
  });

  constructor(
    public dialogRef: MatDialogRef<AdminOstralLettersDecreeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {partnerId: number, businessPartnerId: string},
    private requestService: ApiRequestService,
    protected readonly fb: FormBuilder
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.isSendingRequest = true;

    let apiCallInfo: ApiRequestConfigInfoDefinition = USERS_API_ENDPOINTS_LIST.decreeContingentsByPartners;

    let requestData: {year: number, month: number, partnerId?: number, businessPartnerId?: string} = this.contingentForm.getRawValue();

    requestData.partnerId = this.data.partnerId || 0;
    requestData.businessPartnerId = this.data.businessPartnerId || '';

    this.requestService.doApiRequest(apiCallInfo, requestData).then(result => {
      if(result.status == "success") {
        this.isSendingRequest = false;
        this.downloadKey.setValue(result.data.blobDictionaryKeystr);
      }
    });
  }

}
