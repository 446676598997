import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {companyservice} from "../../shared/messaging-grpc/messaging-grpc";
import ContingentTrade = companyservice.ContingentTrade;

@Component({
  selector: 'users-ostral-contingent-trade-dialog',
  templateUrl: './users-ostral-contingent-trade-dialog.component.html',
  styleUrls: []
})
export class UsersOstralContingentTradeDialogComponent {
  prefix = 'companyClient.trade.';
  ctPrefix = 'companyClient.objectProperties.trade.';
  displayedColumns = ['year', 'month', 'value'];
  constructor(
    public dialogRef: MatDialogRef<UsersOstralContingentTradeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {trade: ContingentTrade}
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
