/* eslint-disable @angular-eslint/component-selector */
import {Component, ViewChild} from '@angular/core';
import {UtilsService} from '../../shared/services/utils.service';
import {SessionService} from '../../shared/session/session.service';

import {ActivatedRoute, Router} from '@angular/router';
import {SmartModalService} from '../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service';
import {SmartTableBuilder} from '../../shared/marketpartner-components/smart-forms/smart-table/builder/smart-table.builder';
import {SmartTableConfigDefinition} from '../../shared/marketpartner-components/smart-forms/smart-table/classes/SmartTableConfigDefinition';
import {StandardViewComponent} from '../../shared/marketpartner-components/standard-view/standard-view.component';
import {MOCKUP_RESPONSES_LIST} from '../requests/mockup-responses-list';
import {SmartTableComponent} from '../../shared/marketpartner-components/smart-forms/smart-table/component/smart-table.component';

import {UserInfoServer} from '../../shared/session/UserInfo';
import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';
import {ApiRequestService} from "../../shared/api-request/service/api-request.service";


@Component({
  selector: 'page-users-contact-marketpartners-content',
  templateUrl: './users-contact-marketpartners.component.html',
  styleUrls: []
})

export class ContactMarketpartnersComponent extends StandardViewComponent {
  @ViewChild('tableMarketPartners') tableMarketPartners?: SmartTableComponent;

  initialized = false;

  tableMarketPartnersConfig?: SmartTableConfigDefinition;
  dataSetMarketPartnersUsers: UserInfoServer[] = [];  // unused, remove?
  mockUpResponsesCopy: any = null;

  constructor(public sessionService: SessionService,
              public activatedRoute: ActivatedRoute,
              private utilsService: UtilsService,
              private apiRequestService: ApiRequestService,
              public smartModalService: SmartModalService,
              public router: Router) {
    super(activatedRoute, router);

    this.mockUpResponsesCopy = this.utilsService.cloneJSON(MOCKUP_RESPONSES_LIST);

    const builder = new SmartTableBuilder()
      .setColumnNameForId('partnerId')
      .addColumnFromId('columnMarketPartnerContactName')
      .addColumnFromId('columnMarketPartnerContactEIC')
      .addColumnFromId('columnMarketPartnerContactRole')
      .addColumnFromInfo({id: 'meteringPointsCompleteFlag', title: 'MeteringPointDirectoryCompletenessColumn',
                          enum: 'MeteringPointDirectoryCompletenessValue', sortable: true, filterable: true})
      .addColumnFromId('columnMarketPartnerContactEmail')
      .addColumnFromId('columnMarketPartnerContactPhone')
      .addInitialSorting({columnId: 'name', order: 'asc'})
      .addToolbarActionFromInfo({
        id: '',
        title: 'ActionDownloadOperatorReport',
        icon: 'pli-download',
        className: 'btn-primary',
        fnClicked: () => this.download()
      })

      .setApiRequestConfigInfoFromInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryContactSearch)
      .showFilters(true)
      .showSearchField(false);

    this.tableMarketPartnersConfig = builder.build();
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);
    await this.loadAllData();
    this.setInitialized(true);
  }

  async loadAllData() {
    await Promise.all([
      // this.loadUsersData()
    ]);
  }

  onRowClicked(_event: any) {
  }

  async download() {
    await this.apiRequestService.downloadFile(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryContactDownload);
  }
}
