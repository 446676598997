<page-loading-spinner [initialized]="initialized"></page-loading-spinner>
<form *ngIf="initialized" [formGroup]="contingentTradeForm" (ngSubmit)="onSubmit()" method="post" class="root fixed-header">
  <div class="headline-space-button">
    <h1>
      <a mat-icon-button color="primary" class="h1-back-button" routerLink="/company/contingents/trade" id="back">
        <mat-icon svgIcon="arrow-back-circle"></mat-icon>
      </a>
      {{prefix + (edit? 'editTrade' : 'newTrade') | translate}}
    </h1>
    <div style="padding-right: 46px">
      <button *ngIf="edit" mat-flat-button id="deleteTradeBtn" type="button" color="warn" (click)="onDelete()">
        {{'ButtonDelete' | translate}}
      </button>
      <span class="ml-4"></span>
      <a mat-stroked-button id="cancelTradeBtn" [routerLink]="'/company/contingents/trade'">
        {{'ModalCancelButton' | translate}}
      </a>
      <submit-button [isSendingRequest]="isSendingRequest" [disabled]="contingentTradeForm.invalid" [textKey]="edit? 'ButtonSave' : (prefix + 'submit')"></submit-button>
    </div>
  </div>
  <div>
    <input type="hidden" name="vnbPartnerId" id="vnbPartnerId" formControlName="vnbPartnerId">
    <input type="hidden" name="cpPartnerId" id="cpPartnerId" formControlName="cpPartnerId">
    <input type="hidden" name="businessPartnerId" id="businessPartnerId" formControlName="businessPartnerId">
    <p>
      <mat-icon svgIcon="info-circle" class="text-info" style="vertical-align: middle; margin-left:12px"/>
      <span style="vertical-align: middle"> {{prefix+'disclaimer' | translate}}</span>
    </p>
    <div class="multi-col-form">
      <div class="col-50">
        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'action' | translate}}</mat-label>
          <mat-select id="tradingAction" formControlName="tradingAction">
            <mat-option *ngFor="let m of ['PURCHASE', 'SALE']" [value]="m" [id]="'tradingAction_'+m">{{ 'TradingAction.'+m | translate }}</mat-option>
          </mat-select>
          <mat-icon matTextPrefix color="warn" *ngIf="!contingentTradeForm.get('tradingAction')?.value" svgIcon="exclamation" style="margin-right: 8px"/>
        </mat-form-field>
        <mat-form-field class="half">
          <mat-label>{{ctPrefix+'periodMonth' | translate}}</mat-label>
          <mat-select id="month" formControlName="month">
            <mat-option *ngFor="let m of [0,1,2,3,4,5,6,7,8,9,10,11]" [value]="m+1" [id]="'month_'+m">{{ 'months.'+m | translate }}</mat-option>
          </mat-select>
          <mat-icon matTextPrefix color="warn" *ngIf="!contingentTradeForm.get('month')?.value" svgIcon="exclamation" style="margin-right: 8px"/>
        </mat-form-field>
        <mat-form-field class="half">
          <mat-label>{{ctPrefix+'periodYear' | translate}}</mat-label>
          <input matInput type="number" step="1" min="1000" formControlName="year" name="year" id="year">
          <mat-icon matTextPrefix color="warn" *ngIf="!contingentTradeForm.get('year')?.value" svgIcon="exclamation" style="margin-right: 8px"/>
        </mat-form-field>
        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'tradedQuantity' | translate}}</mat-label>
          <input matInput type="number" formControlName="tradingQuantity" name="tradingQuantity" id="tradingQuantity">
          <mat-icon matTextPrefix color="warn" *ngIf="!contingentTradeForm.get('tradingQuantity')?.value" svgIcon="exclamation" style="margin-right: 8px"/>
        </mat-form-field>
      </div>

      <div class="col-50">
        <mat-form-field class="half">
          <mat-label>{{ctPrefix+'tradeDate' | translate}}</mat-label>
          <input matInput [matDatepicker]="picker" [formControl]="tradingTimestampDate" name="tradingTimestampDate" id="tradingTimestampDate">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="half">
          <mat-label>{{ctPrefix+'tradeTime' | translate}}</mat-label>
          <input matInput type="text" [formControl]="tradingTimestampTime" name="tradingTimestampTime" id="tradingTimestampTime">
        </mat-form-field>
        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'platform' | translate}}</mat-label>
          <input matInput type="text" formControlName="tradingPlatformOrIntermediary" name="tradingPlatformOrIntermediary" id="tradingPlatformOrIntermediary">
        </mat-form-field>
        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'contingentId' | translate}}</mat-label>
          <input matInput type="text" formControlName="tradingContingentId" name="tradingContingentId" id="tradingContingentId">
        </mat-form-field>
      </div>
    </div>

    <div class="multi-col-form">
      <div class="col-50">
        <h2>{{prefix+'partnerInfo' | translate}}</h2>
        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'company.name' | translate}}</mat-label>
          <input matInput type="text" formControlName="tpCompanyName" name="tpCompanyName" id="tpCompanyName">
          <mat-icon matTextPrefix color="warn" *ngIf="!contingentTradeForm.get('tpCompanyName')?.value" svgIcon="exclamation" style="margin-right: 8px"/>
        </mat-form-field>
        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'company.uid' | translate}}</mat-label>
          <input matInput type="text" formControlName="tpUidNumber" name="tpUidNumber" id="tpUidNumber">
        </mat-form-field>
        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'contingentId' | translate}}</mat-label>
          <input matInput type="text" formControlName="tpContingentId" name="tpContingentId" id="tpContingentId">
        </mat-form-field>
        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'company.responsible' | translate}}</mat-label>
          <input matInput type="text" formControlName="tpResponsiblePerson" name="tpResponsiblePerson" id="tpResponsiblePerson">
        </mat-form-field>
        <mat-form-field class="half">
          <mat-label>{{ctPrefix+'company.contactFirst' | translate}}</mat-label>
          <input matInput type="text" formControlName="tpContactFirstName" name="tpContactFirstName" id="tpContactFirstName">
        </mat-form-field>
        <mat-form-field class="half">
          <mat-label>{{ctPrefix+'company.contactLast' | translate}}</mat-label>
          <input matInput type="text" formControlName="tpContactName" name="tpContactName" id="tpContactName">
        </mat-form-field>

        <mat-form-field class="rowspan-with-very-small">
          <mat-label>{{'FormStreet' | translate}}</mat-label>
          <input matInput type="text" formControlName="tpAddressStreet" name="tpAddressStreet" id="tpAddressStreet">
        </mat-form-field>
        <mat-form-field class="very-small-input">
          <mat-label>{{'FormNr' | translate}}</mat-label>
          <input matInput type="text" formControlName="tpAddressHouseNumber" name="tpAddressHouseNumber" id="tpAddressHouseNumber">
        </mat-form-field>
        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'company.adressSuffix' | translate}}</mat-label>
          <input matInput type="text" formControlName="tpAddressSuffix" name="tpAddressSuffix" id="tpAddressSuffix">
        </mat-form-field>
        <mat-form-field class="small-input">
          <mat-label>{{'FormZip' | translate}}</mat-label>
          <input matInput type="text" formControlName="tpAddressZipCode" name="tpAddressZipCode" id="tpAddressZipCode">
        </mat-form-field>
        <mat-form-field class="rowspan-with-small">
          <mat-label>{{'FormTown' | translate}}</mat-label>
          <input matInput type="text" formControlName="tpAddressTown" name="tpAddressTown" id="tpAddressTown">
        </mat-form-field>

        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'company.email' | translate}}</mat-label>
          <input matInput type="text" formControlName="tpEmail" name="tpEmail" id="tpEmail">
        </mat-form-field>
      </div>

      <div class="col-50">
        <h2>{{prefix+'ownInfo' | translate}}</h2>
        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'company.name' | translate}}</mat-label>
          <mat-select id="cpCompanyName" formControlName="cpCompanyName">
            <mat-option *ngFor="let businessPartner of businessPartners"
                        [value]="businessPartner.name"
                        (click)="patchIds(businessPartner)"
                        [id]="'company_'+businessPartner.id">
              {{businessPartner.name}}
            </mat-option>
          </mat-select>
          <mat-icon matTextPrefix color="warn" *ngIf="!contingentTradeForm.get('cpCompanyName')?.value" svgIcon="exclamation" style="margin-right: 8px"/>
        </mat-form-field>
        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'company.uid' | translate}}</mat-label>
          <input matInput type="text" formControlName="cpUidNumber" name="cpUidNumber" id="cpUidNumber">
        </mat-form-field>
        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'contingentId' | translate}}</mat-label>
          <input matInput type="text" formControlName="cpContingentId" name="cpContingentId" id="cpContingentId">
        </mat-form-field>
        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'company.responsible' | translate}}</mat-label>
          <input matInput type="text" formControlName="cpResponsiblePerson" name="cpResponsiblePerson" id="cpResponsiblePerson">
        </mat-form-field>
        <mat-form-field class="half">
          <mat-label>{{ctPrefix+'company.contactFirst' | translate}}</mat-label>
          <input matInput type="text" formControlName="cpContactFirstName" name="cpContactFirstName" id="cpContactFirstName">
        </mat-form-field>
        <mat-form-field class="half">
          <mat-label>{{ctPrefix+'company.contactLast' | translate}}</mat-label>
          <input matInput type="text" formControlName="cpContactName" name="cpContactName" id="cpContactName">
        </mat-form-field>

        <mat-form-field class="rowspan-with-very-small">
          <mat-label>{{'FormStreet' | translate}}</mat-label>
          <input matInput type="text" formControlName="cpAddressStreet" name="cpAddressStreet" id="cpAddressStreet">
        </mat-form-field>
        <mat-form-field class="very-small-input">
          <mat-label>{{'FormNr' | translate}}</mat-label>
          <input matInput type="text" formControlName="cpAddressHouseNumber" name="cpAddressHouseNumber" id="cpAddressHouseNumber">
        </mat-form-field>
        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'company.adressSuffix' | translate}}</mat-label>
          <input matInput type="text" formControlName="cpAddressSuffix" name="cpAddressSuffix" id="cpAddressSuffix">
        </mat-form-field>
        <mat-form-field class="small-input">
          <mat-label>{{'FormZip' | translate}}</mat-label>
          <input matInput type="text" formControlName="cpAddressZipCode" name="cpAddressZipCode" id="cpAddressZipCode">
        </mat-form-field>
        <mat-form-field class="rowspan-with-small">
          <mat-label>{{'FormTown' | translate}}</mat-label>
          <input matInput type="text" formControlName="cpAddressTown" name="cpAddressTown" id="cpAddressTown">
        </mat-form-field>

        <mat-form-field class="rowspan">
          <mat-label>{{ctPrefix+'company.email' | translate}}</mat-label>
          <input matInput type="text" formControlName="cpEmail" name="cpEmail" id="cpEmail">
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
