import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiRequestService} from "../../../../shared/api-request/service/api-request.service";
import {FormBuilder, Validators} from "@angular/forms";
import {USERS_API_ENDPOINTS_LIST} from "../../../requests/api-endpoints-list";
import {ApiRequestConfigInfoDefinition} from "../../../../shared/api-request/classes/ApiRequestConfigInfoDefinition";

@Component({
  selector: 'download-dialog',
  templateUrl: './admin-ostral-letters-download-dialog.component.html',
  styleUrls: ['./admin-ostral-letters-download-dialog.component.scss']
})
export class AdminOstralLettersDownloadDialogComponent implements OnInit {
  prefix = 'Contingents.'
  isSendingRequest = false;
  dictionary: string[] = [];
  downloadForm = this.fb.nonNullable.group({
    BlobDictionaryKeystr: ['', {validators: Validators.required}]
  });

  constructor(
    public dialogRef: MatDialogRef<AdminOstralLettersDownloadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private requestService: ApiRequestService,
    protected readonly fb: FormBuilder
  ) {
  }

  ngOnInit() {

    this.requestService.doApiRequest(USERS_API_ENDPOINTS_LIST.listAllBlobDictionaryEntries)
      .then(result => {
        if(result.status == 'success') {
          this.dictionary = result.data.blobEntry.map((e: any) => e.blobKey.blobDictionaryKeystr);
        }
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.isSendingRequest = true;

    let apiCallInfo: ApiRequestConfigInfoDefinition = USERS_API_ENDPOINTS_LIST.downloadDataFromBlobDictionaryStreaming;
    let requestData = this.downloadForm.getRawValue();

    this.requestService.downloadFile(apiCallInfo, requestData)
      .then(_ => {
        this.isSendingRequest = false;
          //this.notificationService.showNotification({message: 'DownloadRunningNotification', type: "success", showAsNotification: true});
        this.dialogRef.close(true);
      });
  }

}
