/* eslint-disable @angular-eslint/no-output-on-prefix, @angular-eslint/component-selector, max-len, guard-for-in, @angular-eslint/no-input-rename */
import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EnumUtilsService} from '../../../../services/enum-utils/enum-utils.service';
import {UtilsService} from '../../../../services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {SmartModalService} from '../../smart-modal/service/smart-modal.service';
import {ApiRequestService} from '../../../../api-request/service/api-request.service';
import {SmartModalBuilder} from '../../smart-modal/builder/smart-modal.builder';
import {CustomTranslateService} from '../../../../services/custom-translate.service';
import * as pako from 'pako';
import {assign, escape, get} from 'lodash-es';
import {SmartTableRowActionsInfoDefinition} from '../classes/SmartTableRowActionsInfoDefinition';
import {ApiRequestConfigDefinition} from '../../../../api-request/classes/ApiRequestConfigDefinition';
import {SmartTableColumnDefinition} from '../classes/SmartTableColumnDefinition';
import {SmartToolbarActionDefinition} from '../../smart-shared/classes/SmartToolbarActionDefinition';
import {SmartTableInitialSortingDefinition} from '../classes/SmartTableInitialSortingDefinition';
import {SmartTableConfigDefinition} from '../classes/SmartTableConfigDefinition';
import {ActionEvent} from '../../smart-shared/classes/ActionEvent';
import {NameValueMap} from '../../smart-shared/classes/KeyValueMap';
import {TableColumnFilterFixed, TableColumnFilters} from '../classes/TableColumnFilters';
import {SmartCssClassesByTypeDefinition} from '../../smart-shared/classes/SmartCssClassesByTypeDefinition';
import {ActionsToolbarComponent} from '../../actions-toolbar/actions-toolbar.component';
import {SmartModalConfigDefinition} from '../../smart-modal/classes/SmartModalConfigDefinition';
import {ColumnFilterOperator, TableColumnFilter, TableColumnNameFilter} from '../classes/TableColumnFilter';
import {DateTimeService} from '../../../../services/date-time.service';
import {SmartTableRowClassesDefinition} from '../classes/SmartTableRowClassesDefinition';
import * as moment from 'moment-timezone';

declare var $: any;

@Component({
  selector: 'smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: ['./smart-table.component.scss']
})

export class SmartTableComponent implements AfterViewInit, OnInit {
  constructor(
    public utilsService: UtilsService,
    public translate: TranslateService,
    private customTranslateService: CustomTranslateService,
    private apiRequestService: ApiRequestService,
    private smartModalService: SmartModalService,
    private dateTimeService: DateTimeService,
    public enumUtilsService: EnumUtilsService) {

    this.tableId = 'table-' + utilsService.getRandomUID();

    this.getRowIdValueFromRowData = this.getRowIdValueFromRowData.bind(this);
    this.buildSortingAndFiltersObject = this.buildSortingAndFiltersObject.bind(this);
    this.offlineDatatableFiltering = this.offlineDatatableFiltering.bind(this);
  }
  @Input('offlineData') offlineData: any[] = [];
  @Input('tableConfig') tableConfig?: SmartTableConfigDefinition;

  heightScrollContainer = 'auto-scrollheight'; // Values: "auto-scrollheight", "fixed-large-scrollheight" (495px)
  tableTitle = '';
  fnRowClasses: SmartTableRowClassesDefinition[] = [];
  columnsInfo: InternalColumnDefinition[] = [];
  rowActionsInfo?: SmartTableRowActionsInfoDefinition;
  actionsToolbar: SmartToolbarActionDefinition[] = [];
  showIndividualFilters = false;
  showRowActionButtonOnlyIcon = false;
  cssClassesByType: SmartCssClassesByTypeDefinition = {};
  initialSorting: SmartTableInitialSortingDefinition[] = [];
  apiRequestConfig?: ApiRequestConfigDefinition;

  hideFooter = false;
  hidePageLength = false;
  hideSearchField = false;
  hideHeader = false;
  hideFilteredFromMaxEntriesFooterString = true; // filtered from _MAX_ total entries
  enableOnRowClick = false;
  pageLength = 10;
  splitTable = false;

  hidePageSelector = false;
  columnNameForId = 'id';
  specialColumnsIndexes: NameValueMap<number> = {};
  lastTableSortingStr?: string;
  filtersColumnsByColumnIndex: TableColumnFilters = {};
  fixedFiltersColumns: TableColumnFilterFixed[] = [];

  @Output() onActionClicked = new EventEmitter<ActionEvent>();
  @Output() onRowClicked = new EventEmitter<any>();

  tableId = '';
  toDisplayData: any[] = [];
  pageScrollPos = 0;

  countTotalEntries = 0;
  dataTableObject: any = null;
  @ViewChild('actionsToolbarRef') actionsToolbarRef?: ActionsToolbarComponent;
  fnTransformRawData: (_: any) => any = row => row;

  addNewColumForTable(columnInfo: SmartTableColumnDefinition, position: string, addColumnToo: boolean, contentToAdd = '') {
    const self = this;
    switch (position) {
      case 'before':
        if  (contentToAdd !== 'none'  && this.toDisplayData) {
          for (let index = 0; index < this.toDisplayData.length; index++) {
            switch (contentToAdd) {
              case 'object':
                this.toDisplayData[index].unshift(JSON.parse(JSON.stringify(this.toDisplayData[index])));
                break;
              default:
                this.toDisplayData[index].unshift(contentToAdd);
                break;
            }
          }
        }


        if (addColumnToo) {
          this.columnsInfo.unshift(columnInfo);

          Object.keys(this.specialColumnsIndexes).forEach(function(columnKey: string) {
            self.specialColumnsIndexes[columnKey]++;
          });
          this.specialColumnsIndexes[columnInfo.id] = 0;
        }
        break;
      case 'after':
      default:
        if (contentToAdd !== 'none' && this.toDisplayData) {
          for (let index = 0; index < this.toDisplayData.length; index++) {
            switch (contentToAdd) {
              case 'object':
                this.toDisplayData[index].push(JSON.parse(JSON.stringify(this.toDisplayData[index])));
                break;
              default:
                this.toDisplayData[index].push(contentToAdd);
                break;
            }
          }
        }

        if (addColumnToo) {
          this.columnsInfo.push(columnInfo);
          this.specialColumnsIndexes[columnInfo.id] = this.columnsInfo.length - 1;
        }
        break;
    }
  }

  getSpecialColumnIndex(columnId: string): number | undefined {
    if (columnId in this.specialColumnsIndexes) {
      return this.specialColumnsIndexes[columnId];
    } else {
      return undefined;
    }
  }

  getDataByIdFromRowData(rowData: any): any {
    const indexAllRawData = this.getColumnIndexFromId('__allRawData');
    if (indexAllRawData !== undefined ) {
      return JSON.parse(pako.inflate(atob(rowData[indexAllRawData]), { to: 'string' }));
    } else {
      return null;
    }
  }

  getDialogForActionConfirmation(_actionId: string, actionInfo: SmartToolbarActionDefinition): SmartModalConfigDefinition {
    return new SmartModalBuilder()
      .setTitle(actionInfo.confirmationDialog ? actionInfo.confirmationDialog.title : actionInfo.title)
      .setBodyText(actionInfo.confirmationDialog ? actionInfo.confirmationDialog.body : '')
      // .setModalBckgNoWhite(true)
      .showConfirmButton(true, actionInfo.confirmationDialog ? actionInfo.confirmationDialog.confirmButtonText : undefined)
      .showCancelButton(true, actionInfo.confirmationDialog ? actionInfo.confirmationDialog.cancelText : undefined)
      .setConfirmDangerous(actionInfo.confirmationDialog ? (!!actionInfo.confirmationDialog.confirmationIsDangerous) : false)
      .build();
  }

  actionToolbarClicked(event: ActionEvent) {
    const actionInfo = this.getActionInfoFromToolbar(event.actionId);
    if (actionInfo) {
      this.performAction(actionInfo, this.utilsService.unflattenObject(event.data));
    }
  }

  actionButtonClicked(actionId: string, rowData: any) {
    let rowDataByIndex = rowData;
    if (Array.isArray(rowData)) {
      rowDataByIndex = this.getDataByIdFromRowData(rowData);
    }

    const actionInfo = this.getActionInfoFromRows(actionId);
    if (actionInfo) {
      this.performAction(actionInfo, [this.utilsService.unflattenObject(rowDataByIndex)]);
    }
  }

  performAction(actionInfo: SmartToolbarActionDefinition, dataForAction: any[]) {
    const self = this;
    const actionId = actionInfo.id;
    const actionEvent = {
      actionId: actionId,
      data: dataForAction
    };

    const emit = () => {
      if (actionInfo.fnClicked) {
        actionInfo.fnClicked(actionEvent);
      } else {
        self.onActionClicked.emit(actionEvent);
      }
    };

    if (actionInfo.confirmationDialog ) {
      const modalOptionsForAction: any = self.getDialogForActionConfirmation(actionId, actionInfo);
      modalOptionsForAction.onSuccessFormValue = emit;
      self.smartModalService.showModal(modalOptionsForAction);
    } else {
      emit();
    }
  }

  getActionInfoFromToolbar(actionId: string): SmartToolbarActionDefinition | undefined {
    if (this.actionsToolbar) {
      return this.actionsToolbar.find(thisActionInfo => (thisActionInfo.id === actionId));
    } else {
      return undefined;
    }
  }

  getActionInfoFromRows(actionId: string): SmartToolbarActionDefinition | undefined {
    if (this.rowActionsInfo && this.rowActionsInfo.actions) {
      return this.rowActionsInfo.actions.find(thisActionInfo => thisActionInfo.id === actionId);
    } else {
      return undefined;
    }
  }

  getColumnIndexFromId(columnId: string): number | undefined {
    const idx = this.columnsInfo.findIndex(cur => cur.id === columnId);
    return (idx >= 0 ? idx : undefined);
  }

  getHTMLActionsRow(rowData: any, _indexRow: number): string {
    // Check if action is visible and or enabled/disabled
    const self = this;
    const rowDataById = this.getDataByIdFromRowData(rowData);
    const rowIdValue = self.getRowIdValueFromRowData(rowDataById, false);

    const actionsHTMLArray: string[] = [];
    if (this.rowActionsInfo && this.rowActionsInfo.actions) {
      this.rowActionsInfo.actions.forEach(function(thisActionInfo: SmartToolbarActionDefinition) {
        let actionIsVisible = true;
        let actionIsEnabled = true;
        if (thisActionInfo.fnVisible) {
          actionIsVisible = thisActionInfo.fnVisible(rowDataById);
        }
        if (thisActionInfo.fnEnabled) {
          actionIsEnabled = thisActionInfo.fnEnabled(rowDataById);
        }

        if (actionIsVisible) {

          if (thisActionInfo.specialButton) {
            switch (thisActionInfo.specialButton) {
              case 'details-arrow':
                actionsHTMLArray.push(
                  `<div class="special-button-description"> ${ escape(self.translate.instant(thisActionInfo.title))}
                    <div class="details-arrow btn table-action-button ${thisActionInfo.className || ''}" data-actionid="${ thisActionInfo.id}"  data-rowidvalue="${rowIdValue}" >
                      <i class="fal fa-chevron-circle-right"></i>
                    </div>
                  </div>`);
                break;
            }
          } else {
            actionsHTMLArray.push(
              `<button ${ (!actionIsEnabled ? ' disabled=disabled ' : '')}
                      class=" ${(thisActionInfo.icon ? 'btn-labeled ' : ' ')} btn table-action-button ${thisActionInfo.className || ''} "
                      data-rowidvalue="${rowIdValue}"
                      data-actionid="${thisActionInfo.id}"
                      data-toggle="tooltip" title="${escape(self.translate.instant(thisActionInfo.title))}">

                  ${(thisActionInfo.icon ? `<i class="btn-label ${ thisActionInfo.icon}"></i>` : '')}

                  <span>${escape(self.translate.instant(thisActionInfo.title))}</span>
                </button>`
            );
          }
        }
      });
    }
    return  actionsHTMLArray.join(' ') ;
  }

  ngOnInit() {
    const self = this;
    this.toDisplayData = this.offlineData ? JSON.parse(JSON.stringify(this.offlineData)) : [];
    this.countTotalEntries = this.toDisplayData.length;
    if (this.tableConfig) {
      this.columnsInfo = JSON.parse(JSON.stringify(this.tableConfig.columnsInfo));
      this.showIndividualFilters = this.tableConfig.showIndividualFilters;
      this.showRowActionButtonOnlyIcon = this.tableConfig.showRowActionButtonOnlyIcon;
      this.tableTitle = this.tableConfig.tableTitle;
      this.actionsToolbar = this.tableConfig.actionsToolbar;

      if (this.tableConfig.cssClassesByType) { this.cssClassesByType = this.tableConfig.cssClassesByType; }
      if (this.tableConfig.columnNameForId) { this.columnNameForId = this.tableConfig.columnNameForId; }
      if (this.tableConfig.splitTable) { this.splitTable = this.tableConfig.splitTable; }
      if (this.tableConfig.apiRequestConfig) { this.apiRequestConfig = JSON.parse(JSON.stringify(this.tableConfig.apiRequestConfig)); }

      this.hideFooter = this.tableConfig.hideFooter;
      this.hidePageLength = this.tableConfig.hidePageLength;
      this.hideSearchField = this.tableConfig.hideSearchField;
      this.hideHeader = this.tableConfig.hideHeader;

      if (this.tableConfig.initialSorting) { this.initialSorting = this.tableConfig.initialSorting; }
      if (this.heightScrollContainer) { this.heightScrollContainer = this.tableConfig.heightScrollContainer; }

      this.enableOnRowClick = this.tableConfig.enableOnRowClick;
      this.fnTransformRawData = this.tableConfig.fnTransformRawData;
      this.fnRowClasses = this.tableConfig.fnRowClasses;

      if (this.tableConfig.pageLength) { this.pageLength = this.tableConfig.pageLength;  }
      this.fixedFiltersColumns = this.tableConfig.fixedFiltersColumns;
    }
    this.toDisplayData = this.toDisplayData.map(this.fnTransformRawData);


    this.columnsInfo.forEach((thisColumnInfo: InternalColumnDefinition, _indexColumn: number) => {
      if (thisColumnInfo.title) {
        thisColumnInfo.title = self.translate.instant(thisColumnInfo.title);
      } else {
        thisColumnInfo.title = '';
      }

      if (thisColumnInfo.fieldType === 'date') {
        thisColumnInfo.type = thisColumnInfo.displayAs ? thisColumnInfo.displayAs : 'localedateL';
        thisColumnInfo.render = {
          'sort': (linuxTimestamp?: number) => linuxTimestamp || -1,
          '_': (linuxTimestamp?: number) => this.renderDate(linuxTimestamp, thisColumnInfo.type),
        };
      }
      if (thisColumnInfo.icon) {
        thisColumnInfo.title +=  '<i class="' + thisColumnInfo.icon + '"></i>';
      }
    });

    this.preProcessRawData();
    this.addAllRawDataColumn(true);
    this.addActionsColumn(true);
    this.postProcessRawData();

    this.columnsInfo.forEach(function(thisColumnInfo: InternalColumnDefinition, _indexColumn: number) {
      if (thisColumnInfo.className === undefined) {
        thisColumnInfo.className = '';
      }
      thisColumnInfo.className += ' ' + 'dt-col-index-' + _indexColumn;
    });
  }

  public removeData(toRemoveIds: string[]) {
    if (!toRemoveIds || toRemoveIds.length === 0 ) {
      return;
    }
    const self = this;
    let countRemovedIds = 0;
    const countToRemoveIds = toRemoveIds.length;
    if ((self.toDisplayData &&  self.toDisplayData.length)) {
      for (let indexChecking = self.toDisplayData.length - 1; indexChecking >= 0; indexChecking--) {
        const thisRowIdValue = self.getRowIdValueFromRowData(self.toDisplayData[indexChecking], true);
        if ( thisRowIdValue !== null && toRemoveIds.indexOf(thisRowIdValue) >= 0) {
          self.toDisplayData.splice(indexChecking, 1);
          countRemovedIds++;
          if (countToRemoveIds === countRemovedIds) {
            break;
          }
        }
      }

      self.dataTableObject.rows().every( function (this: any, _rowIdx: number, _tableLoop: any, _rowLoop: any ) {
        const thisRowData: any  = this.data();
        if (thisRowData) {
          const thisIdValue = self.getRowIdValueFromRowData(thisRowData, true);
          if ( (thisIdValue !== null) && toRemoveIds.indexOf(thisIdValue) >= 0) {
            this.remove();
          }
        }
      });
    }
    if ( countRemovedIds > 0) {
      self.pageScrollPos = $('.smart-table-container#' + self.tableId + ' div.dataTables_scrollBody').scrollTop() || 0;
      self.dataTableObject.draw(false);
      $('.smart-table-container#' + self.tableId + ' div.dataTables_scrollBody').scrollTop(self.pageScrollPos);
      this.refreshSelected();
    }
  }

  public reload(resetPagination = false, callbackLoaded: any = null) {
    if (this.apiRequestConfig && this.apiRequestConfig.info) {
      // See documentation for datatables ajax.reload() on:
      // https://datatables.net/reference/api/ajax.reload()
      this.dataTableObject.ajax.reload( callbackLoaded, resetPagination );
    } else {
      this.dataTableObject.draw(resetPagination);
      this.recalcTableDimension();
    }
  }

  public recalcTableDimension() {
    const recalc = () => {
      this.dataTableObject.columns.adjust().responsive.recalc();
      this.refreshSelected();
      this.refreshRowEvents();
    };
    recalc();
    setTimeout(recalc);
  }
  public reloadNewData(newData: any[]) {
    const  self = this;
    if (self.toDisplayData === null) {
      self.toDisplayData = [];
    }

    self.countTotalEntries = (self.toDisplayData && self.toDisplayData.length) ?  self.toDisplayData.length : 0 ;

    self.toDisplayData = JSON.parse(JSON.stringify(newData))
      .map(self.fnTransformRawData);
    this.preProcessRawData();
    this.postProcessRawData();
    self.dataTableObject.clear().rows.add( self.toDisplayData).draw(false);
  }

  addActionsColumn(addColumnToo: boolean) {
    if (this.tableConfig && this.tableConfig.rowActionsInfo) {
      this.rowActionsInfo = this.tableConfig.rowActionsInfo;
      if (this.rowActionsInfo.showColumn) {
        const isVisible = this.rowActionsInfo.actions.length > 0;
        this.addNewColumForTable({
          id: '__actions',
          className: 'actions-column-container',
          visible: isVisible,
          title: this.translate.instant(this.rowActionsInfo.columnTitle ? this.rowActionsInfo.columnTitle : ''),
          sortable: false,
          showAsHTML: true
        }, this.rowActionsInfo.showColumn, addColumnToo, '');
      }
    }
  }

  addAllRawDataColumn(addColumnToo: boolean) {
    this.addNewColumForTable({
      id: '__allRawData',
      title: '',
      sortable: false,
      visible: false
    }, 'after', addColumnToo, 'none');
  }

  preProcessRawData() {
    const self = this;
    if (self.toDisplayData && self.toDisplayData.length) {
      let hasNumericalIndexes = false;
      if (self.toDisplayData.length) {
        hasNumericalIndexes = Array.isArray(self.toDisplayData[0]);
      }
      for (let indexDataRow = 0; indexDataRow < self.toDisplayData.length; indexDataRow++) {
        const wholeObject = JSON.parse(JSON.stringify( self.toDisplayData[indexDataRow]));
        if (!hasNumericalIndexes) {
          // Loop through all the columns if a value is not set on the object set it to "null"
          for (let currentColumnIndex = 0; currentColumnIndex < this.columnsInfo.length; currentColumnIndex++) {
            const thisColumnInfo = this.columnsInfo[currentColumnIndex];
            // Get columns' values that reference to object properties like: "myObject1.property2.subproperty1"
            self.toDisplayData[indexDataRow][thisColumnInfo.id ] = get(
              self.toDisplayData[indexDataRow], thisColumnInfo.id, null);
          }

          const newDataRowObject: any[] = [];
          Object.keys(self.toDisplayData[indexDataRow]).forEach(function(columnKey: string, _columnIndex: number) {
            const columnTargetIndex = self.getColumnIndexFromId(columnKey);
            if (columnTargetIndex !== undefined ) {
              newDataRowObject[columnTargetIndex] = self.toDisplayData[indexDataRow][columnKey];
            }
          });

          self.toDisplayData[indexDataRow] = newDataRowObject;
        }

        // Add the whole object at the end
        const allRawDataIndex = this.getSpecialColumnIndex('__allRawData');
        const encodedWholeObject = btoa(pako.deflate(JSON.stringify(wholeObject), { to: 'string' }));
        if (allRawDataIndex === undefined) {
          self.toDisplayData[indexDataRow].push(encodedWholeObject);
        } else {
          self.toDisplayData[indexDataRow][allRawDataIndex] = encodedWholeObject;
        }
      }
    }
  }

  postProcessRawData() {
    const self = this;

    // modify the source data
    if (this.tableConfig) {
      if (this.tableConfig.rowActionsInfo) {
        this.rowActionsInfo = this.tableConfig.rowActionsInfo;
        if (this.rowActionsInfo.showColumn) {
          const indexActionColumn = this.getSpecialColumnIndex('__actions');
          if (indexActionColumn === undefined) {
            console.error('Index Actions Column is not defined');
          } else {
            if (self.toDisplayData) {
              for (let indexDataRow = 0; indexDataRow < this.toDisplayData.length; indexDataRow++) {
                const actionsData = self.getHTMLActionsRow(this.toDisplayData[indexDataRow], indexDataRow);
                this.toDisplayData[indexDataRow][indexActionColumn] = actionsData;
              }
            }
          }
        }
      }
      if (this.tableConfig.columnsInfo) {
        this.tableConfig.columnsInfo.forEach(function(_thisColumnInfo: SmartTableColumnDefinition, indexColumn: number) {
          if (self.toDisplayData) {
            for (let indexDataRow = 0; indexDataRow < self.toDisplayData.length; indexDataRow++) {
              self.toDisplayData[indexDataRow][indexColumn] = self.getPostProcessCellValue(indexColumn, self.toDisplayData[indexDataRow][indexColumn]);
            }
          }
        });
      }
    }
    self.hidePageSelector = self.isOnlyOnePageAvailable();
  }

  getPostProcessCellValue(indexColumn: number, rawCellValue: any): any {
    let toReturn = rawCellValue;
    if (!this.tableConfig) {
      return toReturn;
    }

    const thisColumnInfo =  this.tableConfig.columnsInfo[indexColumn];
    if ( thisColumnInfo.enum) {
      if ((rawCellValue === null)
          || (rawCellValue === undefined)) {
        // not defined
        toReturn = '';
      } else {
        if(typeof rawCellValue !=  "string" && typeof rawCellValue != "boolean") {
          console.error('Attention: rawCellValue has invalid type, expected a string (or boolean)!\n' +
              'Trying to convert value to String. Please check table data.\n' +
              'Value is ', rawCellValue, ' of type ', typeof rawCellValue, ' in column (index) ', indexColumn);
          try {
            rawCellValue = rawCellValue.toString();
          } catch (e) {
            rawCellValue = 'ERROR DISPLAYING DATA';
          }
        }
        const splittedSelectedOptions = (rawCellValue === true ||  rawCellValue === false) ? [rawCellValue] : rawCellValue.split(',');
        for (let indexSelectedOption = 0; indexSelectedOption < splittedSelectedOptions.length; indexSelectedOption++) {
          let valueToCheckInEnum = splittedSelectedOptions[indexSelectedOption];
          if (thisColumnInfo.valueIsBoolean) {
            if (valueToCheckInEnum === true) {
              valueToCheckInEnum = '1';
            } else if (valueToCheckInEnum === false) {
              valueToCheckInEnum = '0';
            }
          }
          const foundEnumLabel = this.enumUtilsService.getLabelFromEnumValue(
            thisColumnInfo.enum,
            valueToCheckInEnum);

          if (foundEnumLabel !== undefined) {
            splittedSelectedOptions[indexSelectedOption] = this.translate.instant(foundEnumLabel);
          }
        }
        toReturn = splittedSelectedOptions.join(', ');
      }
    } else if (thisColumnInfo.fieldType === 'date') {
      // note: this fieldType is used for both date and timestamp
      let linuxTimestamp;  // empty or cannot be parsed
      if (rawCellValue && rawCellValue.hasOwnProperty('month')) {
        // custom API date object (structured as expected by Java/JS, but different month definition)
        linuxTimestamp = this.dateTimeService.getLinuxFromJsonDate(rawCellValue);
      } else if (rawCellValue) {
        linuxTimestamp = moment(rawCellValue).valueOf();
      }
      return linuxTimestamp;
    }

    if ( !thisColumnInfo.showAsHTML) {
      toReturn = this.utilsService.escapeHtml(toReturn);
    }
    return toReturn;
  }

  private renderDate(linuxTimestamp?: number, displayAs?: string): string {
    if (!linuxTimestamp) return '';
    switch (displayAs) {
      case 'localedatetimeL' :
        return this.dateTimeService.getDateTimeAsLocaleFormatLinux(linuxTimestamp);
      case 'localedatetimeLss' :
        return this.dateTimeService.getDateTimeAsLocaleFormatLinux(linuxTimestamp, true);
      case 'localedateL' :
        return this.dateTimeService.getDateAsLocaleFormatLinux(linuxTimestamp);
    }
    return '';
  }

  getRowDataByIdValue(idValue: string): any | null {
    let match = false;
    let result: any = null;
    for (let thisIndexRow = 0; thisIndexRow < this.toDisplayData.length; thisIndexRow++ ) {
      if (this.getRowIdValueFromRowData(this.toDisplayData[thisIndexRow], true) === (idValue + '') ) {
        if (match) {
          console.error('getRowDataByIdValue(): id is not unique in table:', idValue, result);
          return null;
        } else {
          match = true;
          result = this.getDataByIdFromRowData(this.toDisplayData[thisIndexRow]);
        }
      }
    }
    return result;
  }

  refreshSelected() {
    if (!this.actionsToolbarRef) {
      return;
    }

    const selectedId = this.actionsToolbarRef.getSelectedId();
    if (selectedId !== null) {
      // If something was already selected check that an entry with that 'id' still exists
      // if not then  unselect it. In case it does, update the data
      const rowData = this.getRowDataByIdValue(selectedId);
      if ( rowData === null ) {
        this.actionsToolbarRef.setSelectedData(null, null);
      } else {
        this.actionsToolbarRef.setSelectedData(selectedId, [rowData]);
      }
    }
  }

  clickedRowCell(rowCell: any) {
    if (!this.enableOnRowClick || !this.actionsToolbarRef) {
      return;
    }

    const self = this;
    let  row = $(rowCell).parent();

    if ( $(row).hasClass('selected') ) {
      $(row).removeClass('selected');
      this.actionsToolbarRef.setSelectedData(null, null);
    } else {
      $('.smart-table-container#' + self.tableId + ' tr.selected').removeClass('selected');
      $(row).addClass('selected');

      if (!self.dataTableObject.row(row).data()) {
        row = $(row).parent().find('tr:first');
      }
      const rowDataById = this.getDataByIdFromRowData(self.dataTableObject.row(row).data());
      const rowEvent = rowDataById;

      this.actionsToolbarRef.setSelectedData( self.getRowIdValueFromRowData(rowDataById, false), [rowDataById]);
      this.onRowClicked.emit(this.utilsService.unflattenObject(rowEvent));
    }
  }

  refreshRowEvents() {
    const self = this;
    setTimeout(() =>  {
      $('.smart-table-container#' + self.tableId + ' .table-action-button').off('click').on('click', function(this: any) {
        const thisContext: any = this;
        const actionId = $( thisContext ).data( 'actionid' );
        const idValue = $( thisContext ).data( 'rowidvalue' );
        const decodedData =  self.getRowDataByIdValue(idValue);
        self.actionButtonClicked(actionId, decodedData );
      });
    }, 0);
  }

  refreshTableData(resetPagination = false) {
    this.dataTableObject.ajax.reload( null, resetPagination );
  }

  async ngAfterViewInit() {
    // See documentation for datatables on:
    // https://datatables.net
    const self = this;
    const orderForDataTable: any[] = [];
    if ( this.initialSorting.length) {
      // See documentation for datatables order on:
      // https://datatables.net/reference/option/order
      this.initialSorting.forEach(function(thisSortInfo) {
        orderForDataTable.push([self.getColumnIndexFromId(thisSortInfo.columnId), thisSortInfo.order]);
      });
    }

    const t = (s: string) => this.translate.instant(s);
    const language = {
      sEmptyTable: t('DataTable.sEmptyTable'),
      sInfo: t('DataTable.sInfo'),
      sInfoEmpty: t('DataTable.sInfoEmpty'),
      sInfoFiltered: t('DataTable.sInfoFiltered'),
      sInfoPostFix: '',
      sInfoThousands: t('DataTable.sInfoThousands'),
      sLengthMenu: t('DataTable.sLengthMenu'),
      sLoadingRecords: t('DataTable.sLoadingRecords'),
      sProcessing: t('DataTable.sProcessing'),
      sSearch: t('DataTable.sSearch'),
      sZeroRecords: t('DataTable.sZeroRecords'),
      oPaginate: {
        sFirst:    '<i class=\"pli-arrow-left-2\"></i>',
        sLast:     '<i class=\"pli-arrow-right-2\"></i>',
        sNext:     '<i class=\"pli-arrow-right\"></i>',
        sPrevious: '<i class=\"pli-arrow-left\"></i>'
      },
      oAria: {
        sSortAscending: t('DataTable.oAria.sSortAscending'),
        sSortDescending: t('DataTable.oAria.sSortDescending'),
      },
      select: {
        rows: {
          '_': t('DataTable.select.rows._'),
          '0': '',
          '1': t('DataTable.select.rows.1'),
        }
      },
      buttons: {
        print: t('DataTable.buttons.print'),
        colvis: t('DataTable.buttons.colvis'),
        copy: t('DataTable.buttons.copy'),
        copyTitle: t('DataTable.buttons.copyTitle'),
        copyKeys: t('DataTable.buttons.copyKeys'),
        copySuccess: {
          '_': t('DataTable.buttons.copySuccess._'),
          '1': t('DataTable.buttons.copySuccess.1'),
        }
      }
    };
    if (self.hideFilteredFromMaxEntriesFooterString) {
      language.sInfoFiltered = '';
    }

    // See documentation for datatables options on:
    // https://datatables.net/reference/option/
    const dataTableOptions: any = {
      language,
      responsive:  /*false,*/ {
        details: {
          // @ts-ignore
          display: $.fn.dataTable.Responsive.display.childRowImmediate,
          type: ''
        }
      },
      dom: 'frtipl',
      order: orderForDataTable,
      pageLength: this.pageLength,
      columnDefs: [],
      pagingType: 'full_numbers',
      lengthMenu: [10, 25, 50, 100, 250],
      data: this.toDisplayData,
      columns: this.columnsInfo,

      deferRender:    false,

      createdRow: function( row: Node, data: any, dataIndex: number ) {
        // See documentation for datatables createdRow on:
        // https://datatables.net/reference/option/createdRow
        const allExtraClasses: string[] = [];

        self.fnRowClasses.forEach(function(thisFunctionRowClasses: SmartTableRowClassesDefinition) {
          const thisFunctionExtraClasses = thisFunctionRowClasses(self.getDataByIdFromRowData(data), dataIndex);
          if (thisFunctionExtraClasses) {
            allExtraClasses.push(thisFunctionExtraClasses);
          }
        });

        $(row).addClass(allExtraClasses);
      },
      rowCallback: function( _row: Node, _data: any , _displayNum: number, _displayIndex: number, _dataIndex: number ) {},

      drawCallback : function( _settings: any ) {
        // See documentation for datatables drawCallback on:
        // https://datatables.net/reference/option/drawCallback
        self.refreshRowEvents();
        self.hidePageSelector = self.isOnlyOnePageAvailable();
      },
      initComplete: function () {
        // See documentation for datatables initComplete on:
        // https://datatables.net/reference/option/initComplete
        if (self.showIndividualFilters) {
          this.api().columns().every( function (columnIndex: number) {
            const thisColumnInfo = self.columnsInfo[columnIndex];
            if (!thisColumnInfo.filterable) return;

            const filterIconHTML = `<i data-colindex="${columnIndex}"  class="filter-column-icon fa fa-filter"></i>`;
            const filterContentHTML = self.getFilterContentHTML(columnIndex);

            const filterHTML =
              `<div data-colindex="${columnIndex}" class="filter-container">
              ${filterIconHTML}
              ${filterContentHTML}
              </div>`;

            const allFilterContainerSelector = self.getAllFilterContainerSelector();
            const filterContainerSelector = self.getFilterContainerSelector(columnIndex);
            $(filterHTML)
              .appendTo($(`.smart-table-container#${self.tableId} .dataTables_wrapper > table > thead > tr > th.dt-col-index-${columnIndex}`))
              .on( 'click', function (e: Event) {
                e.stopPropagation();
              });

            $(`${filterContainerSelector} .filter-column-icon`).on('click', function() {
              if ($(filterContainerSelector).hasClass('open-filter') ) {
                $(filterContainerSelector).removeClass('open-filter');
              } else {
                // Close all filters first
                $(allFilterContainerSelector).removeClass('open-filter');
                $(filterContainerSelector).addClass('open-filter');
                $(filterContainerSelector + ' .filter-focus-initial').focus();
              }
            });

            $(`${filterContainerSelector} .filter-select`).on('change', function() {
              const selectedValue = <string | undefined>$(`${filterContainerSelector} .filter-select`).val();
              self.changedFilterValue(columnIndex, selectedValue, thisColumnInfo.filterLength);
            });

            $(`${filterContainerSelector} .filter-input`).on('keypress', function(e: any) {
              e.stopPropagation();
              if (e.keyCode === 13) {
                // Intro pressed, close filters
                $(allFilterContainerSelector).removeClass('open-filter');
              }
            });
            $(`${filterContainerSelector} .filter-input`).on('keyup', function(e: any) {
              e.stopPropagation();
              const value =  <string | undefined>$(`${filterContainerSelector} .filter-input`).val();
              self.changedFilterValue(columnIndex, value, thisColumnInfo.filterLength);
            });

            $(`${filterContainerSelector}`).on('keyup', function(e: any) {
              e.stopPropagation();
            });
            $(`${filterContainerSelector}`).on('keypress', function(e: any) {
              e.stopPropagation();
              if (e.keyCode === 13) {
                // Intro pressed, close filters
                $(allFilterContainerSelector).removeClass('open-filter');
              }
            });

            $(`${filterContainerSelector} .filter-btn-clear`).on('click', function() {
              self.clearFilterValue(columnIndex);
            });
            $(`${filterContainerSelector} .filter-btn-accept`).on('click', function() {
              self.acceptFilterValue(columnIndex);
            });

            $(`${filterContainerSelector} .datepicker-not-init`).each(function() {
              // @ts-ignore
              $( this ).removeClass( 'datepicker-not-init' );

              // See documentation for bootstrap-datepicker on:
              // https://bootstrap-datepicker.readthedocs.io/en/latest/options.html*/
              // @ts-ignore
              $(this).find( 'input.form-control' ).datepicker({
                autoclose: true,
                todayBtn: 'linked',
                clearBtn: false, // event seems to not be working, value will not be unset
                // todayHighlight: true,
                language:  self.customTranslateService.getCurrentLangShort(),
                format: self.dateTimeService.getLocaleDateFormatPicker(),
              }).on('changeDate', function(e: any) {
                const formattedIsoDate = self.dateTimeService.getDateAsIsoFormatFullISOString(e.date);
                // @ts-ignore
                const thisFilterRangeType = $(this).data('filter-range-type');
                self.changedFilterValue(columnIndex, formattedIsoDate, thisColumnInfo.filterLength, thisFilterRangeType);
              });
            });
          } );
        }
      }
    };

    if (this.apiRequestConfig && this.apiRequestConfig.info) {
      dataTableOptions.serverSide = true;
      if (this.apiRequestConfig.info.useMockup) {
        dataTableOptions.ajax = async function ( d: any, _callback: any, _settings: any ) {
          d.columns = null;
          // all info for mockup
          const allMockupTableInfo = self.buildSortingAndFiltersObject(d, true);
          if (! self.apiRequestConfig || !self.apiRequestConfig.info) { return []; }
          const apiMockupResult = await self.apiRequestService.doApiRequest(self.apiRequestConfig.info, allMockupTableInfo);
          _callback(apiMockupResult);
          return apiMockupResult;
        };
      } else {
        dataTableOptions.ajax = async function ( d: any, _callback: any, _settings: any ) {
          d.columns = null;
          const data = self.buildSortingAndFiltersObject(d);
          if (! self.apiRequestConfig || !self.apiRequestConfig.info) { return []; }
          if (self.apiRequestConfig.body) {
            assign(data, self.apiRequestConfig.body);
          }
          // 'cache': true,  // sure? can add this if required, but currently not supported by doApirequest
          const options = self.apiRequestConfig.options;
          const apiResult = await self.apiRequestService.doApiRequest(self.apiRequestConfig.info, data,
                                                                      options.handleLoading, options.handleErrorNotification);
          _callback(apiResult);
          return apiResult;
        };
      }
    } else {
      // Add offline extra search functionality
      $.fn.dataTable.ext.search.push(self.offlineDatatableFiltering);
      dataTableOptions.data = this.toDisplayData;
    }

    this.dataTableObject = $('#' + self.tableId + '_container')
      .DataTable( dataTableOptions )
      .on( 'responsive-display', function ( _e: any, _datatable: any, _columns: any ) {
        // See documentation for datatables responsive-display on:
        // https://datatables.net/reference/event/responsive-display
        // NOTE: Do not remove next line, or action buttons will not work on responsive display (ex: collapsing, and opening a row that displays in 2 lines)
        self.refreshRowEvents();
      } )
      .on( 'responsive-resize', function ( _e: any, _datatable: any, _columns: any ) {
        // See documentation for datatables responsive-resize on:
        // https://datatables.net/reference/event/responsive-resize
        // NOTE: Do not remove next line, or action buttons will not work on responsive size
        self.refreshRowEvents();
      } )
      .on( 'length.dt', function ( _e: any, _settings: any, len: number ) {
        self.pageLength = len;
      } )
      .on('order.dt', function () {
        // self.refreshDataFiltered();
        const currentOrderStr = JSON.stringify(self.dataTableObject.order());
        if (self.lastTableSortingStr !== currentOrderStr) {
          self.lastTableSortingStr = currentOrderStr;
          // self.refreshDataFiltered();
        }
      })
    /* .on('click', 'tbody tr td:not(.actions-column-container)', function() {

       }) */
      .on('xhr.dt', function ( _e: any, _settings: any, json: any, _xhr: any ) {
        // See documentation for datatables xhr.dt on:
        // https://datatables.net/reference/event/xhr
        let pageInfo: any = {};
        if (json.data) {
          if (json.data instanceof Array) {
            self.toDisplayData = JSON.parse(JSON.stringify(json.data))
              .map(self.fnTransformRawData);
            if (json.pageInfo) {
              json.recordsTotal = pageInfo.totalElements;
              json.recordsFiltered = pageInfo.totalElements;
            } else if ('totalElements' in json) {
              json.recordsTotal = json.totalElements;
              json.recordsFiltered = json.totalElements;
            } else {
              // All records at once?
              json.recordsTotal = json.data.length;
              json.recordsFiltered = json.data.length;
            }
          } else {
            // json.data is not an array but an object
            if (json.data.pageInfo) {
              pageInfo = json.data.pageInfo;
              delete json.data.pageInfo;
              json.recordsTotal = pageInfo.totalElements;
              json.recordsFiltered = pageInfo.totalElements;
            }
            // there should be only one other key on the object, containing the root element
            const jsonDataKeys = Object.keys(json.data);
            if (!jsonDataKeys || jsonDataKeys.length !== 1 ) {
              console.error('Json Data contains ', jsonDataKeys.length, ' keys. Should be 1.');
            } else {
              self.toDisplayData = JSON.parse(JSON.stringify(json.data[jsonDataKeys[0]]))
                .map(self.fnTransformRawData);
            }
          }
        }

        // Go through the objects and make them flat
        // Ex:
        // "communicationData": {
        //    "receiveMessageLocation": "LOCAL",
        //    "localFtpAccount": {
        //       "hostname": "placeholder",
        //       "username": "12X-0000000858-F"
        //    }
        // }
        // Will be:
        // "communicationData:receiveMessageLocation": "LOCAL",
        // "communicationData:localFtpAccount:hostname": "placeholder",
        // "communicationData:localFtpAccount:username": "12X-0000000858-F",

        // Update the counter of total entries so it can be accessed by other components
        if (json.recordsTotal !== undefined) {
          self.countTotalEntries = json.recordsTotal;
        }

        self.toDisplayData = self.utilsService.flattenObject(self.toDisplayData, true);
        self.preProcessRawData();
        self.postProcessRawData();
        json.data = self.toDisplayData;
      })
      .on('preXhr.dt', function ( _e: any, _settings: any, _data: any ) {
        // See documentation for datatables preXhr.dt on:
        // https://datatables.net/reference/event/preXhr
      } )
      .on('click', function(this: any) {
        self.clickedRowCell(this);
      });
  }

  // See documentation for datatables responsive-resize on:
  // https://datatables.net/manual/plug-ins/search
  offlineDatatableFiltering(settings: any, _searchData: any , _rowIndex: number, rowData: any, _counter: number )  {
    const self = this;
    if ( settings.nTable.id !== self.tableId + '_container' ) {
      return true;
    }

    let displayThisRow = true;
    const allFilterColumnsColumnIndexes = Object.keys(self.filtersColumnsByColumnIndex);
    for (let index = 0; index < allFilterColumnsColumnIndexes.length; index++ ) {
      const columnIndexStr = allFilterColumnsColumnIndexes[index];
      const indexColumnFilteredInt = parseInt(columnIndexStr, 10);
      const thisColumnData = rowData[indexColumnFilteredInt] + '';
      const thisColumnInfo = self.columnsInfo[indexColumnFilteredInt];
      const thisColumnFilterInfo = self.filtersColumnsByColumnIndex[indexColumnFilteredInt];
      if (!self.checkOfflineFilterCondition(thisColumnInfo.id,  thisColumnData, thisColumnFilterInfo)) {
        displayThisRow = false;
        break;
      }
    }

    if (displayThisRow && self.fixedFiltersColumns) {
      for (let index = 0; index < self.fixedFiltersColumns.length; index++ ) {
        const thisFixedColumnFilter = self.fixedFiltersColumns[index];
        const indexColumnFilteredInt = self.getColumnIndexFromId(thisFixedColumnFilter.columnId);
        if (indexColumnFilteredInt !== undefined) {
          const thisColumnData = rowData[indexColumnFilteredInt] + '';
          const thisColumnInfo = self.columnsInfo[indexColumnFilteredInt];
          const thisColumnFilterInfo = thisFixedColumnFilter.columnFilter;
          if (!self.checkOfflineFilterCondition(thisColumnInfo.id,  thisColumnData, thisColumnFilterInfo)) {
            displayThisRow = false;
            break;
          }
        }
      }
    }


    return displayThisRow;
  }

  checkOfflineFilterCondition(columnId: string, columnData: any, columnFilterInfo?: TableColumnFilter ) {
    const self = this;
    let matchedRow = true;
    if (columnFilterInfo === undefined || columnFilterInfo.value === undefined || columnFilterInfo.value === '' || columnFilterInfo.value === null   ) {
      return true;
    }
    const columnIndex = self.getColumnIndexFromId(columnId);
    if (columnIndex === undefined) {
      return true;
    }
    const thisColumnInfo = self.columnsInfo[columnIndex];

    const valueToSearchInRenderedColumn = self.getPostProcessCellValue(columnIndex, columnFilterInfo.value);
    const valueEscapedFoRegExp = self.utilsService.quotemeta(valueToSearchInRenderedColumn);
    if (thisColumnInfo.enum) {
      // Match the string on the table, taking in consideration, that the enum field might have more seleted options
      // not only one, all separated by a ','
      const regExp  = '^(' + valueEscapedFoRegExp + ',.*)|(.*,\\s?' + valueEscapedFoRegExp + ')|(' + valueEscapedFoRegExp + ')$';
      matchedRow = new RegExp(regExp, 'i').test(columnData);
    } else {
      switch (columnFilterInfo.operator) {
        case ColumnFilterOperator.Contains:
          matchedRow =  new RegExp('^(.*' + valueEscapedFoRegExp + '.*)$', 'i').test(columnData);
          break;
        case ColumnFilterOperator.NotContains:
          matchedRow =  !(new RegExp('^(.*' + valueEscapedFoRegExp + '.*)$', 'i').test(columnData));
          break;
        case ColumnFilterOperator.Equals:
          matchedRow =  new RegExp('^(' + valueEscapedFoRegExp + ')$', 'i').test(columnData);
          break;
        case ColumnFilterOperator.FromToDate:
          if (thisColumnInfo.fieldType === 'date') {
            const linuxFromDateInputField = columnFilterInfo.value['from-date'] ? self.dateTimeService.getLinuxFromLocale(columnFilterInfo.value['from-date']) : undefined;
            const linuxToDateInputField = columnFilterInfo.value['to-date'] ? self.dateTimeService.getLinuxFromLocale(columnFilterInfo.value['to-date'], '+1') : undefined;
            const linuxFromDate = linuxFromDateInputField ? linuxFromDateInputField : 0;
            const linuxToDate = linuxToDateInputField ? linuxToDateInputField : 10000000000000;
            const linuxDate = moment(columnData || 0).valueOf();
            matchedRow = (linuxDate >= linuxFromDate &&  linuxDate < linuxToDate);
          }
          break;
      }
    }
    return matchedRow;
  }

  setLoadingAction(actionId: string) {
    if (!this.actionsToolbarRef) {
      return;
    }
    this.actionsToolbarRef.setLoadingAction(actionId);
  }
  unsetLoadingAction() {
    if (!this.actionsToolbarRef) {
      return;
    }
    this.actionsToolbarRef.unsetLoadingAction();
  }

  changedFilterValue(columnIndex: number, value?: string, length?: number, subFilter?: string) {
    const self = this;
    const thisColumnInfo = self.columnsInfo[columnIndex];

    this.setFilterActive(columnIndex);
    let filterOperator = ColumnFilterOperator.Contains;
    if (thisColumnInfo.enum) {
      filterOperator = ColumnFilterOperator.Equals;
    }
    let newValue = value;
    if (subFilter) {
      switch (subFilter) {
        case 'from-date':
        case 'to-date':
          filterOperator = ColumnFilterOperator.FromToDate;
          // Object.keys(data).forEach(function(key) { delete data[key]; });
          const currentFilterColumn: TableColumnFilter | undefined = this.filtersColumnsByColumnIndex[columnIndex];
          let currentFullValue = currentFilterColumn && currentFilterColumn.value ? currentFilterColumn.value : undefined;
          if ((value === null || value === '' || value === undefined) ) {
            // value is empty
            if ( currentFullValue && (subFilter in currentFullValue)) {
              // remove it from the value object
              delete currentFullValue[subFilter];
            }
          } else {
            // value is not empty
            if (!currentFullValue) {
              // object is not yet initialized
              currentFullValue = {};
            }
            currentFullValue[subFilter] = value;
          }
          newValue = currentFullValue;
          break;
      }
    }

    if (newValue === undefined) {
      this.filtersColumnsByColumnIndex[columnIndex] = undefined;
    } else {
      this.filtersColumnsByColumnIndex[columnIndex] = {
        'operator': filterOperator,
        'value': newValue
      };
    }


    if (newValue === null || newValue === '' || newValue === undefined) {
      this.unsetFilterActive(columnIndex);
      this.filtersColumnsByColumnIndex[columnIndex] = undefined;
    }
    if(length === undefined || value?.length === length) {
      this.debounce(this.refreshDataFiltered.bind(this), 500)();
    }
  }

  timeoutId: ReturnType<typeof setTimeout> | undefined;
  debounce<T extends Function>(func: T, delay: number): (...args: any[]) => void {
    const context = this;

    return function(this: any, ...args: any[]) {
      clearTimeout(context.timeoutId);
      context.timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }
  unsetFilterActive(columnIndex: number) {
    const filterContainerSelector = this.getFilterContainerSelector(columnIndex);
    $(filterContainerSelector).removeClass('active-filter');
  }
  setFilterActive(columnIndex: number) {
    const filterContainerSelector = this.getFilterContainerSelector(columnIndex);
    if (!$(filterContainerSelector).hasClass('active-filter') ) {
      $(filterContainerSelector).addClass('active-filter');
    }
  }

  clearFilterValue(columnIndex: number) {
    $(`${this.getFilterContainerSelector(columnIndex)} .filter-clear-selector`).val('');
    this.changedFilterValue(columnIndex, undefined);
    // Close the filter
    $(this.getFilterContainerSelector(columnIndex)).removeClass('open-filter');
  }

  acceptFilterValue(columnIndex: number) {
    // Just close the filter
    $(this.getFilterContainerSelector(columnIndex)).removeClass('open-filter');
  }

  getFilterContainerSelector(columnIndex: number) {
    return `.smart-table-container#${this.tableId} .filter-container[data-colindex="${columnIndex}"]`;
  }
  getAllFilterContainerSelector() {
    return `.smart-table-container#${this.tableId} .filter-container`;
  }

  buildSortingAndFiltersObject(data?: any, returnInternalRepresentation = false) {
    const self = this;
    if (!data) { data = {}; }

    const firstEntryIndexPage = data.start || 0; // Indicates the number of the first row on the page. Ex: third page with 10 entries per page, would be 20
    const pageSize: number = data.length || this.pageLength; // Number of entries per page
    const filtersConditions: TableColumnNameFilter[] = [];

    let sortOrder = data.order || [];
    sortOrder = sortOrder.map((order: any) => ({
      columnName: self.columnsInfo[order.column].id,
      order: order.dir.toUpperCase()
    }));

    if (self.filtersColumnsByColumnIndex) {
      Object.keys(self.filtersColumnsByColumnIndex).forEach(function (indexColumnFilteredStr: string) {
        const indexColumnFilteredInt = parseInt(indexColumnFilteredStr, 10);
        const thisColumnFilterInfo = self.filtersColumnsByColumnIndex[indexColumnFilteredInt];
        if (thisColumnFilterInfo) {
          let valueToSearch = thisColumnFilterInfo.value;
          if (self.columnsInfo[indexColumnFilteredInt].valueIsBoolean) {
            if (valueToSearch === '1') {
              valueToSearch = true;
            } else if (valueToSearch === '0') {
              valueToSearch = false;
            }
          }

          filtersConditions.push({
            operator: thisColumnFilterInfo.operator,
            value: valueToSearch,
            columnName: self.columnsInfo[indexColumnFilteredInt].id
          });
        }
      });
    }

    if (self.fixedFiltersColumns) {
      self.fixedFiltersColumns.forEach(function( thisFixedFilterColumn: TableColumnFilterFixed) {
        let valueToSearch = thisFixedFilterColumn.columnFilter.value;
        const columnIndex = self.getColumnIndexFromId(thisFixedFilterColumn.columnId);
        if (columnIndex) {
          if (self.columnsInfo[columnIndex].valueIsBoolean) {
            if (valueToSearch === '1') {
              valueToSearch = true;
            } else if (valueToSearch === '0') {
              valueToSearch = false;
            }
          }
        }

        filtersConditions.push({
          operator: thisFixedFilterColumn.columnFilter.operator,
          value: valueToSearch,
          columnName: thisFixedFilterColumn.columnId
        });
      });
    }

    if (returnInternalRepresentation) {
      return {
        filtersConditions,
        sortOrder,
        firstEntryIndexPage,
        pageSize
      };
    }

    const query: any = {
      andConditions: this.getQueryFilterConditionsForServer(filtersConditions),
      pageRequest: {
        pageIndex: firstEntryIndexPage / pageSize,
        pageSize: pageSize
      },
      sortOrder: sortOrder.length > 0 ? sortOrder : undefined
    };
    return {query};
  }

  getQueryFilterConditionsForServer(filtersConditions: TableColumnNameFilter[]) {
    if (!filtersConditions) {
      return undefined;
    }
    const andConditions: any[] = [];
    filtersConditions.forEach(function(thisFilterCondition: TableColumnNameFilter) {
      let operatorForServer = 'LIKE';
      let addConditionToList = true;
      switch (thisFilterCondition.operator) {
        case ColumnFilterOperator.FromToDate:
          addConditionToList = false;
          if (thisFilterCondition.value && thisFilterCondition.value['from-date'] ) {
            andConditions.push(
              {
                orConditions: [{
                  columnName : thisFilterCondition.columnName,
                  operator : 'GE',
                  values : [thisFilterCondition.value['from-date']]
                }]
              }
            );
          }
          if (thisFilterCondition.value && thisFilterCondition.value['to-date'] ) {
            andConditions.push(
              {
                orConditions: [{
                  columnName : thisFilterCondition.columnName,
                  operator : 'LE',
                  values : [thisFilterCondition.value['to-date']]
                }]
              }
            );
          }
          break;
        case ColumnFilterOperator.NotEquals:
          operatorForServer = 'NE';
          break;
        case ColumnFilterOperator.Equals:
          operatorForServer = 'EQ';
          break;
        case ColumnFilterOperator.NotContains:
          operatorForServer = 'NOTLIKE';
          break;
        case ColumnFilterOperator.Contains:
        default:
          operatorForServer = 'LIKE';
          break;
      }
      if (addConditionToList) {
        andConditions.push(
          {
            orConditions: [{
              columnName : thisFilterCondition.columnName,
              operator : operatorForServer,
              values : [thisFilterCondition.value]
            }]
          }
        );
      }
    });
    return andConditions;
  }

  refreshDataFiltered() {
    const self = this;
    if (self.apiRequestConfig) {
      // Online Request
      this.refreshTableData(true);
    } else {
      // Offline data, filter it (will be done on offlineDatatableFiltering)
      // Clean all previous filters
      self.dataTableObject
      // .search( '' ) // this is the global one
        .columns().search( '' );
      self.dataTableObject.draw();
    }
  }


  getFilterContentHTML(columnIndex: number): string {
    const self = this;
    const thisColumnInfo = self.columnsInfo[columnIndex];

    let fieldType = 'text';
    if (thisColumnInfo.enum) {
      fieldType = 'enum';
    } else if (thisColumnInfo.fieldType) {
      fieldType = thisColumnInfo.fieldType;
    }
    let filterContentHTML = '';

    switch (fieldType) {
      case 'date':
        filterContentHTML =
          `<div class="filter-content custom-dropdown-menu dropdown-menu date-range-filter">
              <div class="filter-date-lines-container">
                  <div class="filter-date-line-container">
                  <div class="filter-date-line-label">${self.translate.instant('FormFromDate')}</div>
                  <div class="input-group date datepicker-not-init"><input data-filter-range-type="from-date" data-filter-column-index="${columnIndex}" id="datepicker-table-filter-input-${self.tableId}-from_${columnIndex}" type="text" class="form-control filter-clear-selector "  value=""  placeholder="${self.dateTimeService.getLocaleDateFormatPicker()}"></div>
                </div>
                <div class="filter-date-line-container">
                  <div class="filter-date-line-label">${self.translate.instant('FormToDate')}</div>
                  <div class="input-group date datepicker-not-init"><input data-filter-range-type="to-date" data-filter-column-index="${columnIndex}" id="datepicker-table-filter-input-${self.tableId}-to_${columnIndex}" type="text" class="form-control filter-clear-selector "  value=""  placeholder="${self.dateTimeService.getLocaleDateFormatPicker()}"></div>
                  <!--<button class="filter-btn filter-btn-clear fa fa-times"  tabIndex= "-1"></button>-->
                </div>
              </div>
              <button class="filter-btn filter-btn-accept fa fa-check"  tabIndex= "-1"></button>
              <button class="filter-btn filter-btn-clear fa fa-times"  tabIndex= "-1"></button>
            </div>`;
        break;
      case 'text':
        filterContentHTML =
          `<div class="filter-content custom-dropdown-menu dropdown-menu string-contains-filter">
              <input data-filter-column-index="${columnIndex}" class="filter-clear-selector filter-input filter-focus-initial" placeholder="${self.translate.instant('TableFilterPlaceholderText')}">
              <button class="filter-btn filter-btn-accept fa fa-check"  tabIndex= "-1"></button>
              <button class="filter-btn filter-btn-clear fa fa-times"  tabIndex= "-1"></button>
            </div>`;
        break;

      case 'enum':
        filterContentHTML =
          `<div class="filter-content custom-dropdown-menu dropdown-menu  enum-contains-filter">
              <select data-filter-column-index="${columnIndex}" class="filter-clear-selector filter-select filter-focus-initial">
                  <option value="" class="enum-contains-filter-option"></option>`;

        const allOptions = thisColumnInfo.enum ? self.enumUtilsService.getEnumValues(thisColumnInfo.enum) : [];
        allOptions.forEach(function (thisOption) {
          if (!thisOption.omitFromDropdown)  {
            filterContentHTML +=
            `<option value="${thisOption.data}" data-value="${thisOption.data}" class="enum-contains-filter-option">${escape(self.translate.instant(thisOption.label))}</option>`;
          }
        });
        filterContentHTML +=
            `</select>
            <button class="filter-btn filter-btn-accept fa fa-check"  tabIndex= "-1"></button>
            <button class="filter-btn filter-btn-clear fa fa-times"  tabIndex= "-1"></button>
          </div>`;
        break;
    }

    return filterContentHTML;
  }

  getRowIdValueFromRowData(rowData: any, dataByIndex: boolean): string | null {
    const self = this;
    let toReturn: string | null = null;
    const columnNameForIdSplitted = this.columnNameForId.split('+');
    columnNameForIdSplitted.forEach(function(thisIdPartColumnName: string, indexIdPart: number) {
      const columnIndexForId =  dataByIndex ? self.getColumnIndexFromId(thisIdPartColumnName) : thisIdPartColumnName;
      if (columnIndexForId !== undefined) {
        if (toReturn === null ) {
          toReturn = rowData[columnIndexForId] + '';
        } else {
          toReturn += rowData[columnIndexForId];
        }
      }
      if ((toReturn !== null) && (indexIdPart < columnNameForIdSplitted.length - 1) ) {
        toReturn += '___';
      }
    });
    return toReturn;
  }

  getCountTotalEntries() {
    return this.countTotalEntries;
  }
  getCountTotalFilteredEntries() {
    let toReturn = 0;
    if (this.dataTableObject) {
      const pageInfo = this.dataTableObject.page.info();
      toReturn = pageInfo.recordsDisplay;
    }
    return toReturn;
  }

  isOnlyOnePageAvailable() {
    let toReturn = false;
    if (this.dataTableObject) {
      const pageInfo = this.dataTableObject.page.info();
      if (pageInfo && (pageInfo.page === 0) && (pageInfo.pages === 1)) {
        toReturn = true;
      }
    }

    if (!(this.apiRequestConfig) &&  (this.toDisplayData.length <= this.pageLength)) {
      toReturn = true;
    }
    return toReturn;
  }
  getCurrentRowsData() {
    const toReturn: any[] = [];
    const self = this;
    this.toDisplayData.forEach(function (thisRowData) {
      toReturn.push( self.getDataByIdFromRowData(thisRowData));
    });
    return toReturn;
  }
}

// additional datatables.net properties
interface InternalColumnDefinition extends SmartTableColumnDefinition {
  render?: any;
}
