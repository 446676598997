import {StandardViewComponent} from '../../shared/marketpartner-components/standard-view/standard-view.component';
import {ApiRequestBuilder} from '../../shared/api-request/builder/api-request.builder';
import {USERS_API_ENDPOINTS_LIST} from '../requests/api-endpoints-list';
import {ActivatedRoute, Router} from '@angular/router';

import {ApiRequestService} from '../../shared/api-request/service/api-request.service';
import {MarketPartnerInfo, MarketPartnerRole, Feature} from '../../shared/session/MarketPartnerInfo';
import {SessionService} from '../../shared/session/session.service';
import {FtpWarnings, MarketPartnerService} from '../../shared/services/marketpartner.service';
import { Component } from '@angular/core';
import {CompanyPartnerInfo} from "../../shared/session/CompanyPartnerInfo";

@Component({ template: '' })
export class MarketpartnerViewComponent extends StandardViewComponent {

  isOperatorMode = false;
  isCompanyPartnerMode = false;
  partnerId: number | undefined;
  marketPartnerInfo?: MarketPartnerInfo;
  companyPartnerInfo?: CompanyPartnerInfo;
  fromDatahubIsConfigured = false;
  ftpWarnings: FtpWarnings;

  constructor( public activatedRoute: ActivatedRoute,
               public router: Router,
               public sessionService: SessionService,
               public marketPartnerService: MarketPartnerService,
               public apiRequestService: ApiRequestService) {
    super(activatedRoute, router);
    this.ftpWarnings = this.marketPartnerService.getFtpWarnings(null);
  }
  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);
    if (params.partnerId) {
      this.isOperatorMode = true;
      this.partnerId = parseInt(params.partnerId);
      if(this.router.url.includes('companypartners')) {
        this.isCompanyPartnerMode = true;
      }
      await this.loadPartnerInfo();
    } else if (this.sessionService.isMarketPartner()) {
      this.isOperatorMode = false;
      this.partnerId = this.sessionService.getCurrentPartnerId();
      await this.loadPartnerInfo();
    } else if (this.sessionService.isOperator()) {
      this.partnerId = 0;
    } else {
      console.error('bug: unhandled case');
    }
  }

  async loadPartnerInfo() {
    if (!this.partnerId) return;
    if(this.isCompanyPartnerMode) {
      const apiCompanyPartnerConfig = new ApiRequestBuilder()
        .setEndpointInfo(USERS_API_ENDPOINTS_LIST.getCompanyPartner, {partnerId: this.partnerId})
        .build();
      const resultApiRequest = await this.apiRequestService.callApi(apiCompanyPartnerConfig);
      if (resultApiRequest.status === 'success') {
        this.companyPartnerInfo = {companyPartnerSettings: resultApiRequest.data.companyPartnerSettings};
      }

    } else {
      const apiMarketPartnerConfig = new ApiRequestBuilder()
        .setEndpointInfo(USERS_API_ENDPOINTS_LIST.marketpartnerDirectoryMarketPartnerInfo, {partnerId: this.partnerId})
        .build();
      const resultApiRequest = await this.apiRequestService.callApi(apiMarketPartnerConfig);
      if (resultApiRequest.status === 'success') {
        this.marketPartnerInfo = resultApiRequest.data;
      }
      await this.refreshSharedFtpAccounts();
    }
  }

  async refreshSharedFtpAccounts() {
    if (this.partnerId && this.marketPartnerInfo) {
      const request = new ApiRequestBuilder()
        .setEndpointInfo(USERS_API_ENDPOINTS_LIST.listSharedFtpAccounts)
        .setBodyInfo({partnerId: this.partnerId}).build();
      const result = await this.apiRequestService.callApi(request);
      if (result.status === 'success') {
        this.marketPartnerInfo.communicationData.sharedFtpAccounts = result.data.localFtpAccounts;  // TODO do we need type mapping here?
      }
      this.sessionService.updatedMarketpartnerInfo(this.partnerId, this.marketPartnerInfo);
      this.ftpWarnings = this.marketPartnerService.getFtpWarnings(this.marketPartnerInfo);
    }
  }

  get marketPartnerRole(): (MarketPartnerRole|undefined) {
    if (!this.marketPartnerInfo) return undefined;
    return this.marketPartnerInfo.partnerSettings.role;
  }

  hasFeature(feature: Feature): boolean {
    if (!this.marketPartnerInfo) return false;
    return this.marketPartnerInfo.availableFeatures.includes(feature);
  }

  get showAdditionalMeteringPointColumns() {
    const role = this.marketPartnerRole;
    return role === 'VNB' || role === 'LF' || role === 'SDV';
  }

  get accessMeteringPointsUpload() {
    const mpi = this.marketPartnerInfo;
    if (!mpi) return false;
    return this.marketPartnerService.canAccessMeteringPointsUpload(mpi);
  }

  get accessMeteringPointsDownload() {
    const mpi = this.marketPartnerInfo;
    if (!mpi) return false;
    return this.marketPartnerService.canAccessMeteringPointsDownload(mpi);
  }
}
