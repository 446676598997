import {Component, ViewChild} from '@angular/core';
import {MarketpartnerViewComponent} from "../shared/marketpartner-view.component";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../shared/session/session.service";
import {MarketPartnerService} from "../../shared/services/marketpartner.service";
import {ApiRequestService} from "../../shared/api-request/service/api-request.service";
import {SmartTableConfigDefinition} from "../../shared/marketpartner-components/smart-forms/smart-table/classes/SmartTableConfigDefinition";
import {SmartTableComponent} from "../../shared/marketpartner-components/smart-forms/smart-table/component/smart-table.component";
import {SmartTableBuilder} from "../../shared/marketpartner-components/smart-forms/smart-table/builder/smart-table.builder";
import {USERS_API_ENDPOINTS_LIST} from "../requests/api-endpoints-list";
import {SmartModalBuilder} from "../../shared/marketpartner-components/smart-forms/smart-modal/builder/smart-modal.builder";
import {TranslateService} from "@ngx-translate/core";
import {SmartModalService} from "../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service";
import {OstralUploadService} from "./ostral-upload.service";
import {ActionEvent} from "../../shared/marketpartner-components/smart-forms/smart-shared/classes/ActionEvent";
import {companyservice} from "../../shared/messaging-grpc/messaging-grpc";

import {ApiRequestBuilder} from "../../shared/api-request/builder/api-request.builder";
import {RowActionEvent} from "../../shared/marketpartner-components/smart-forms/smart-table/classes/SmartTableRowActionDefinition";
import {AppConfig} from "../../config/app.config";
import BusinessPartner = companyservice.BusinessPartner;
import {UserManualService} from "../../shared/services/user-manual.service";
import {MatDialog} from "@angular/material/dialog";
import {ApiRequestConfigDefinition} from "../../shared/api-request/classes/ApiRequestConfigDefinition";
import {ApiRequestConfigInfoDefinition} from "../../shared/api-request/classes/ApiRequestConfigInfoDefinition";
import {
  SmartFormBuilder
} from "../../shared/marketpartner-components/smart-forms/smart-form/builder/smart-form.builder";
import {
  AdminOstralLettersDownloadDialogComponent
} from "../admin-ostral/decree-download-dialogs/download-dialog/admin-ostral-letters-download-dialog.component";
import {
  AdminOstralLettersDialogComponent
} from "../admin-ostral/decree-download-dialogs/all-in-one-dialog/admin-ostral-letters-dialog.component";

@Component({
  selector: 'users-ostral',
  templateUrl: './users-ostral.component.html',
  styleUrls: ['../users-common.scss']
})
export class OstralComponent extends MarketpartnerViewComponent {
  @ViewChild('tableOstral') tableOstral?: SmartTableComponent;

  tableOstralConfig?: SmartTableConfigDefinition;
  private registrationDownloadAllStates = ["UNREGISTERED", "EXPIRED"];
  private registrationDownloadableStates = ["UNREGISTERED", "INVITED", "EXPIRED"];
  private registrationDownloadableWithWarningStates = ["INVITED"];

  constructor( public activatedRoute: ActivatedRoute,
               public router: Router,
               public sessionService: SessionService,
               public marketPartnerService: MarketPartnerService,
               public apiRequestService: ApiRequestService,
               private translate: TranslateService,
               private smartModalService: SmartModalService,
               private ostralUploadService: OstralUploadService,
               private userManualService: UserManualService,
               public dialog: MatDialog) {
    super(activatedRoute, router, sessionService, marketPartnerService, apiRequestService);
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);
    this.tableOstralConfig = this.buildTable();
    this.setInitialized(true);
  }

  private buildTable(): SmartTableConfigDefinition {
    return new SmartTableBuilder()
      .setPageLength(100)
      .setColumnNameForId('businessPartnerId')
      .addColumnFromInfo({id: 'businessPartnerId', title: 'Ostral.Company.BusinessPartnerId', visible: true, filterable: true})
      .addColumnFromInfo({id: 'name', title: 'Ostral.Company.CompanyName', visible: true, filterable: true})
      .addColumnFromInfo({id: 'contactFirstName', title: 'Ostral.Company.ContactFirstName', visible: true, filterable: true})
      .addColumnFromInfo({id: 'contactName', title: 'Ostral.Company.ContactName', visible: true, filterable: true})
      .addColumnFromInfo({id: 'addressStreet', title: 'Ostral.Company.AddressStreet', visible: true, filterable: true})
      .addColumnFromInfo({id: 'addressHouseNumber', title: 'Ostral.Company.AddressHouseNumber', visible: true, filterable: true})
      .addColumnFromInfo({id: 'addressZipCode', title: 'Ostral.Company.AddressZipCode', visible: true, filterable: true})
      .addColumnFromInfo({id: 'addressTown', title: 'Ostral.Company.AddressTown', visible: true, filterable: true})
      .addColumnFromInfo({id: 'registrationStatus', title: 'Ostral.Company.RegistrationStatus', visible: true, filterable: true, enum: 'registrationStatus'})

      .addRowClassFromId('rowClassesRegistrationStatus')

      .addInitialSorting({columnId: 'businessPartnerId', order: 'asc'})
      .addToolbarActionFromInfo({id: '', title: 'Ostral.ActionUpload',
        icon: 'pli-upload', className: 'btn-primary',
        fnClicked: () => this.upload()})
      .addToolbarActionFromInfo({id: 'downloadRegistrationCodes', title: 'Ostral.DownloadRegistrationCode',
        icon: 'pli-download', className: 'btn-primary',
        fnClicked: (event) => this.onActionPerformed(event)})

      .addToolbarActionFromInfo({
        id: '',
        title: 'Contingents.lettersDownload',
        icon: 'pli-download',
        className: 'btn-primary',
        fnVisible: () => this.isOperatorMode,
        fnClicked: () => this.openDownloadDialog()
      })

      .addRowActionFromInfo({id: 'ostral_details_view',
        title: 'Ostral.ViewDetails',
        icon: 'pli-eye',
        className: 'btn-mint',
        fnClicked: (actionEvent) => this.view(actionEvent)})

      .addRowActionFromInfo({
        id: 'downloadRegistrationCode',
        title: 'Ostral.DownloadRegistrationCode',
        icon: 'pli-download',
        className: 'btn-mint',
        fnEnabled: (selectedElement: BusinessPartner) => this.isStatusIn(selectedElement.registrationStatus, this.registrationDownloadableStates)
      })

      .addRowActionFromInfo({
        id: 'downloadDataForContingencyLetter',
        title: 'Ostral.DownloadDataForContingencyLetter',
        icon: 'pli-plug-in',
        className: 'btn-danger',
        fnVisible: () => this.isOperatorMode
      })

      .addRowActionFromInfo({
        id: 'deleteBusinessPartner',
        title: 'Ostral.DeleteBusinessPartner',
        icon: 'pli-trash',
        className: 'btn-danger'
      })

      .setApiRequestConfigInfoFromInfo(USERS_API_ENDPOINTS_LIST.listBusinessPartners, {
        partnerId: this.partnerId
      })
      .showFilters(true)
      .showSearchField(false)
      .build();
  }

  async onActionPerformed(event: ActionEvent) {
    const entitySelected = event.data.length ? event.data[0] : null;

    switch (event.actionId) {
      case 'downloadRegistrationCode':
        this.showDownloadModal(entitySelected);
        break;
      case 'deleteBusinessPartner':
        this.showDeleteModal(entitySelected);
        break;
      case 'downloadDataForContingencyLetter':
        this.openLetterDialog(entitySelected);
        break;
      case 'downloadRegistrationCodes':
        this.showDownloadModal(undefined);
        break;
    }
  }

  showDownloadModal(selectedBusinessPartner: BusinessPartner | undefined) {
    let showWarning = selectedBusinessPartner === undefined ? false :
      this.isStatusIn(selectedBusinessPartner.registrationStatus, this.registrationDownloadableWithWarningStates);

    const formConfig =  new SmartFormBuilder()
      .addFormFieldFromInfo({
        name: 'status', title: this.translate.instant('Ostral.DownloadRegistrationCodeConfirmTextAddon'),
        type: 'checkboxmulti', optionsAsList: true,
        disabled: false,
        default: 'UNREGISTERED',
        allowedValues: {type: 'localDefined', data: this.registrationDownloadAllStates.map(label => ({label: this.translate.instant('Ostral.Company.'+label), data: label}))}
      })
      .showSubmitButton(true)
      .showCancelButton(true)
      .setSubmitButtonText(this.translate.instant('commonButtonsNames.confirm'))
      .build();

    const bodyHtml = showWarning ?
      this.translate.instant('Ostral.RegistrationCodeAlreadyDownloaded'):
      "";
    const confirmationDialogBuilder = new SmartModalBuilder()
      .setTitle('Ostral.DownloadRegistrationCode')
      .setBodyHtml(bodyHtml +
        this.translate.instant('Ostral.DownloadRegistrationCodeConfirmText')
            .replaceAll("{{manualUrl}}",this.userManualService.getSerialLetterTemplateLink()))
      .showConfirmButton(true)
      .showCancelButton(true)
      .setModalCSSClassSize("md");

    if(selectedBusinessPartner === undefined) {
      confirmationDialogBuilder
        .setFormConfigFromInfo(formConfig)
        .showConfirmButton(false)
        .showCancelButton(false);

    }
    const confirmationDialog = confirmationDialogBuilder.build();

    confirmationDialog.onSuccessFormValue = (states) => this.downloadRegistrationCode(selectedBusinessPartner, states);
    this.smartModalService.showModal(confirmationDialog);
  }

  async downloadRegistrationCode(entitySelected: BusinessPartner | undefined, states: {status?: any[]}) {
    if(this.partnerId == undefined || states !== undefined && states.status !== undefined && states.status.length == 0) {
      return;
    }

    let entities: BusinessPartner[] = [];
    if(entitySelected === undefined) {
      const options: ApiRequestConfigDefinition = {
        info: USERS_API_ENDPOINTS_LIST.listBusinessPartners,
        options: {handleErrorNotification: true},
        body: {
          partnerId: this.partnerId,
          query: {
            andConditions: [],
            pageRequest: {
              pageIndex: 0,
              pageSize: 9999999
            },
            sortOrder: [{
              columnName: "businessPartnerId",
              order: "ASC"
            }]
          }
        }};
      const result = await this.apiRequestService.callApi(options);
      if (result.status == 'success' && result.data) {
        entities = result.data.businessPartners
          .filter((c: BusinessPartner) =>
            this.isStatusIn(c.registrationStatus, states.status!));
      } else {
        return;
      }
    } else {
      entities.push(entitySelected);
    }

    if(entities.length > 0) {
      let apiCallInfo: ApiRequestConfigInfoDefinition = USERS_API_ENDPOINTS_LIST.downloadRegistrationCode;
      this.apiRequestService.downloadFile(apiCallInfo, {
        PartnerId: this.partnerId.toString(),
        BusinessPartnerId: entities.map(e => e.businessPartnerId! as string).join(",")
      }).then(_ => setTimeout(() => this.tableOstral?.reload(), 0)); // FIXME: async foo
    }
  }

  openLetterDialog(entitySelected: BusinessPartner) {
    if(this.partnerId == undefined) {
      return;
    }

    this.dialog.open(AdminOstralLettersDialogComponent, {
      data: {
        partnerId: this.partnerId,
        businessPartnerId: entitySelected.businessPartnerId! as string
      },
    });
  }

  async openDownloadDialog() {
    this.dialog.open(AdminOstralLettersDownloadDialogComponent, {
      data: {},
    });
  }

  showDeleteModal(selectedBusinessPartner: BusinessPartner) {

    const confirmationDialog = new SmartModalBuilder()
      .setTitle('Ostral.DeleteBusinessPartnerConfirmTitle')
      .setBodyHtml(this.translate.instant('Ostral.DeleteBusinessPartnerConfirmText')
        .replace('{{gpNr}}', selectedBusinessPartner.businessPartnerId)
        .replace('{{gpName}}', selectedBusinessPartner.name)
        .replace('{{gpContact}}', selectedBusinessPartner.contactFirstName + " " + selectedBusinessPartner.contactName))
      .showConfirmButton(true)
      .showCancelButton(true)
      .setModalCSSClassSize("md")
      .setModalCSSClassSize("md")
      .build();
    confirmationDialog.onSuccessFormValue = () => this.deleteBusinessPartner(selectedBusinessPartner);
    this.smartModalService.showModal(confirmationDialog);
  }

  async deleteBusinessPartner(entitySelected: BusinessPartner) {
    if(this.partnerId == undefined) {
      return;
    }
    const deleteBusinessPartnerApiRequestConfig = new ApiRequestBuilder()
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.deleteBusinessPartner)
      .setBodyInfo({partnerId: this.partnerId, businessPartnerId: entitySelected.businessPartnerId})
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .build();
    /*const deleteCompanyApiRequest =*/ await this.apiRequestService.callApi(deleteBusinessPartnerApiRequestConfig);
    //console.log(deleteCompanyApiRequest);
    this.tableOstral?.reload();
  }

  async upload() {
    const file = await this.apiRequestService.selectFile('.csv, .zip');
    if (!file) return;
    console.log('file.name:', file.name);

    if (file.name == undefined) {
      const title = 'Ostral.ImportNotAllowedInvalidFilenameTitle';
      let message = 'Ostral.ImportNotAllowedInvalidFilenameText';
      message = this.translate.instant(message, {filename: file.name});
      const modalOptions = new SmartModalBuilder()
        .setTitle(title)
        .setBodyText(message)
        .showConfirmButton(true, 'ModalCloseButton')
        .setModalCSSClassSize('md')
        .build();
      await this.smartModalService.showModal(modalOptions);
      return;
    }

    await this.ostralUploadService.startUpload(file);
    this.tableOstral!.reload();
  }

  view(event: RowActionEvent) {
    if(this.partnerId == undefined) {
      return;
    }
    const entitySelected = event.data[0] as BusinessPartner;
    //console.log(entitySelected);
    this.navigateToInfo(this.partnerId, entitySelected.businessPartnerId as string);
  }

  navigateToInfo(mpId: number, gpId: string) {
    if (gpId && mpId) {

      if (this.isOperatorMode) {
        this.navigateTo({
          'route': AppConfig.routes.admin_marketpartner_ostral_gp_info,
          'replace': {'partnerId': this.partnerId, mpId, gpId}
        });
      } else {
        this.navigateTo({
          'route': AppConfig.routes.ostral_gp_info,
          'replace': {mpId, gpId}
        });
      }
    } else {
      console.error('navigateToInfo(', mpId, gpId, ')');
      throw new Error('navigateToInfo');
    }
  }

  isStatusIn(state: companyservice.RegistrationStatus, statesToFilter: string[]) {
    type RegistrationStatus = keyof typeof companyservice.RegistrationStatus;
    return statesToFilter.includes(state as any as RegistrationStatus);
  }
}
