import { Injectable } from '@angular/core';
import {COMPANYPARTNERS_API_ENDPOINTS_LIST} from "./api-endpoints-list";
import {ApiRequestService} from "../shared/api-request/service/api-request.service";
import {ConsumptionDataCompanyPartner} from "../shared/chart/consumption-data-model";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MeteringDataService {

  consumptionData$ = new BehaviorSubject<ConsumptionDataCompanyPartner | undefined>(undefined);
  isLoading = false;

  constructor(private requestService: ApiRequestService) { }

  getConsumptionData(partnerId: number) {
    if(this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.requestService.doApiRequest(COMPANYPARTNERS_API_ENDPOINTS_LIST.getConsumptionData, {partnerId: partnerId})
      .then(result => {
        this.consumptionData$.next(result.data);
        this.isLoading = false;
      });
  }
}
