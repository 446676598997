export class MeteringPoint {
  [x: string]: any;
  valid: {
    from: {year: number, month: number, day: number} | null,
    to: {year: number, month: number, day: number} | null
  };

  constructor(properties: any) {
    Object.keys(properties).forEach(k => this[k] = properties[k]);
    this.valid = {
      from: properties.valid.from ? {year: properties.valid.from.year, month: properties.valid.from.month, day: properties.valid.from.day} : null,
      to: properties.valid.to ? {year: properties.valid.to.year, month: properties.valid.to.month, day:properties.valid.to.day} : null
    };
  }
}
